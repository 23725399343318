import { Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { AddStoreNotification } from "../../Functions/StoreNotification";
import { config } from "../../Functions/Constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../Footer";

const CompanyProfile = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("weinigPortalUser")));
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState({})

    useEffect(()=> {
        handleSearch()
      }, [])

    async function handleSearch(){
        setIsLoading(true)
        try {
            const getAccounts = () => axios({
                method: "POST",
                url: config.url.API + "/Accounts/search-accounts",
                data: JSON.stringify({
                  searchCriteria: user.accountId,
                  searchtype: "sfId",
                }),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "https://weinigportal.com/",
                  Authorization: "Bearer " + user.token,
                },
              });
            var response = await getAccounts();

            if (response.data.statusCode === 401) {
              setIsLoading(false)
                AddStoreNotification(
                  "How did you get there?",
                  "danger",
                  "You're not authorized to be on that page."
                );
                navigate("/");
              } else if (response.data.statusCode === 204) {
                setIsLoading(false)
                AddStoreNotification(
                  "It's dangerous to go alone",
                  "info",
                  "There was no account matching that criteria"
                );
              } else if (response.data.statusCode === 422) {
                setIsLoading(false)
                AddStoreNotification(
                  "Error",
                  "danger",
                  "The server experienced an unknown error"
                );
              } else if (!!response.data) {
                setIsLoading(false)
                setCompany(response.data[0])
              }
          } catch (error) {
            setIsLoading(false)
            AddStoreNotification("Error","danger", error.message);
          }
    }

    async function handleAccountUpdate(){
      var tempAccount = company;
      try {
          const updateCompany = () => axios({
              method: "POST",
              url: config.url.API + "/Accounts/update-account",
              data: JSON.stringify(tempAccount),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://weinigportal.com/",
                Authorization: "Bearer " + user.token,
              },
            });
          var response = await updateCompany();

          if (response.data.statusCode === 401) {
              AddStoreNotification(
                "How did you get there?",
                "danger",
                "You're not authorized to be on that page."
              );
              navigate("/");
            } else if (response.data.statusCode === 204) {
              AddStoreNotification(
                "It's dangerous to go alone",
                "info",
                ""
              );
            } else if (response.data.statusCode === 422) {
              AddStoreNotification(
                "Error",
                "danger",
                "The server experienced an unknown error"
              );
            } else if (response.data.statusCode === 200) {
              handleSearch();
              AddStoreNotification(
                "Success",
                "success",
                "Account information has been successfuly updated"
              );
            }
        } catch (error) {
          AddStoreNotification("Error","danger", error.message);
        }
  }

    function handleAccountChange(e) {

        switch (e.target.name) {
          case "company":
            setCompany({
              ...company,
              companyName: e.target.value});
            break;
          case "phone":
            setCompany({
              ...company,
              phoneNumber: e.target.value});
            break;
          case "email":
            setCompany({
              ...company,
              emailAddress: e.target.value});
            break;
          case "contact":
            setCompany({
              ...company,
              primaryContact: e.target.value});
            break;
          case "id":
            setCompany({
              ...company,
              sfid: e.target.value});
            break;
          case "address":
            setCompany({
              ...company,
              streetAddress: e.target.value});
            break;
          case "city":
            setCompany({
              ...company,
              city: e.target.value});
            break;
          case "state":
            setCompany({
              ...company,
              state: e.target.value});
            break;
          case "zip":
            setCompany({
              ...company,
              zipCode: e.target.value});
            break;
          default:
            break;
        }
      }

    return(
    <Box width="100%" height="calc(100% - 52px)" backgroundColor="#161616" display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" overflow="auto">
        <Box width="97%" paddingBottom="50px">
            <Paper sx={{marginTop:"20px", overflow:"hidden", background:"#202020", borderRadius:"0px"}} elevation={0} square>
                <Box style={{alignItems:'center', flex:10}}>
                <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"bold"} color={"white"}>{user.company} account details</Typography>
                {Object.keys(company).length !== 0 &&
                  <Box>
                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                        <TextField name="company" size="small" variant="filled" label="Company" value={company.companyName} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="email" size="small" variant="filled" label="Email" value={company.emailAddress} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="phone" size="small" variant="filled" label="Phone" value={company.phoneNumber} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="contact" size="small" variant="filled" label="Contact"value={company.primaryContact} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>
                    </Box>
                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                        <TextField name="address" size="small" variant="filled" label="Address" value={company.streetAddress} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="city" size="small" variant="filled" label="City" value={company.city} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="state" size="small" variant="filled" label="State" value={company.state} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>

                        <TextField name="zip" size="small" variant="filled" label="Zip" value={company.zipCode} onChange={(e) =>{handleAccountChange(e)}} 
                        sx={{width:"25%", borderBottom: "1px solid white"}} 
                        InputProps={{style: {fontSize:13.5, color:"white"}, disableUnderline:true}} 
                        InputLabelProps={{style: {fontSize:13.5, color:"#B6B6B6"}}}/>
                    </Box>
                    <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                        <Box sx={{width:"100%"}}>
                        <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={handleAccountUpdate}>
                            Update
                        </Button>
                        </Box>
                    </Box>
                  </Box>
                }
                </Box>
            </Paper>
            {isLoading && <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}><CircularProgress sx={{margin:"20px 0px 0px 0px", color:"#00A551"}} size="30px"/></Box>}
        </Box>
        
        <Footer />
    </Box>
    )
}
export default CompanyProfile;