import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import MVLabelerImg from "../../../imgs/Pages/Products/Labeler/Labeler.png";
import MVLabelerLogo from "../../../imgs/Pages/Products/Labeler/LabelerLogoSolid.png";
import LabelerImage1 from "../../../imgs/Pages/Products/Labeler/LabelerImage1.png";
import LabelerImage2 from "../../../imgs/Pages/Products/Labeler/LabelerImage2.png";
import LabelerImage3 from "../../../imgs/Pages/Products/Labeler/LabelerImage3.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const MVLabeler = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", height:"60%"}}>
                    <Box sx={{width:"110%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="MVLabeler Logo" src={MVLabelerLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}>Create <span style={{color:"#008FFF"}}>labels</span> seamlessly.</Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>Label creation couldn’t be easier or more
                                customizable. </Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #008FFF"}}}>Buy now</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#008FFF", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%"}}>
                        <img alt="MVLabeler Main" src={MVLabelerImg} style={{width:"100%", height:"100%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%", background:"white"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#008FFF"}}>Customizable</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Custom design your parts, panels,
                         and assembly labels with scannable QR codes and barcodes.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#008FFF"}}>Simple</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Labeler has an easy-to-use interface
                         that is simple but proficient. With Labeler, you can easily create labels to identify your parts throughout the machining process.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#008FFF"}}>Practical</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}> Choose the necessary data on your
                         component labels to ensure each job is grouped correctly for expeditious assembly and shipment to your customers.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Create <span style={{color:"#008FFF"}}>Labels</span> for different scenarios</Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="MVLabeler Labels for Parts" src={LabelerImage1} style={{width:"50%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#008FFF", fontWeight:"bold"}}>Labels for Parts</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Labeler allows for the creation of labels for all your different parts.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                             textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #008FFF"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#008FFF", fontWeight:"bold"}}>Labels for Sheetgood Patterns</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>With Labeler, you can create labels for your sheet-good
                             patterns and print individual labels for selected pieces.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                             textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #008FFF"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="MVLabeler Labels for Sheetgood Patterns" src={LabelerImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <img alt="MVLabeler Labels for Nesting" src={LabelerImage3} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#008FFF", fontWeight:"bold"}}>Labels for Nesting</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Create labels for Nesting with Labeler.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                             textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #008FFF"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls='true' width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default MVLabeler;