import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import AccumulatorImg from "../../../imgs/Pages/Products/Accumulator/Accumulator.png";
import AccumulatorLogo from "../../../imgs/Pages/Products/Accumulator/AccumulatorLogoSolid.png";
import AccumulatorImage1 from "../../../imgs/Pages/Products/Accumulator/AccumulatorImage1.png";
import AccumulatorImage2 from "../../../imgs/Pages/Products/Accumulator/AccumulatorImage2.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const Accumulator = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", background:"black", height:"65%"}}>
                    <Box sx={{width:"110%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="Accumulator Logo" src={AccumulatorLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}>Wide floor planking has <br/> never been <span style={{color:"#8100FF"}}>easier.</span></Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>Process and gather your flooring orders 
                                in a method that allows you to see real-time production and feedback and create packing lists.</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                    textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #8100FF"}}}>Buy now</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"black",
                                    color:"#8100FF", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                    width:"90px", fontWeight:"bold", '&:hover':{color:"#6D00D8", background:"black", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>  
                    </Box>
                    <Box sx={{width:"100%"}}>
                        <img alt="Accumulator Main" src={AccumulatorImg} style={{width:"100%", height:"100%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#8100FF"}}>Order</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Create custom flooring orders based
                         on the square footage of your customer's specifications and flooring width range.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#8100FF"}}>Calculate</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Automatically calculate ripping
                         and chopping requirements per order.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#8100FF"}}>Accurately Ship</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Accumulator keeps perfect track of
                         cut square footage per width to ensure accurate customer shipments.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span style={{color:"#8100FF"}}>Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="Accumulator Real Time Production Feedback" src={AccumulatorImage1} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#8100FF", fontWeight:"bold"}}>Real Time Production Feedback</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>See real-time production metrics such as required sq/ft and
                             produced sq/ft.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                            textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #8100FF"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#8100FF", fontWeight:"bold"}}>Packing Slips</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Download packing slips for your orders and view different metrics.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                            textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #8100FF"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="Accumulator Packing Slips" src={AccumulatorImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls='true' width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default Accumulator;