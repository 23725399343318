import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, IconButton, Collapse, Modal } from "@mui/material";
import { Box } from "@mui/system"
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AddStoreNotification } from "../../Functions/StoreNotification";
import { config } from "../../Functions/Constants";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Software = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("weinigPortalUser")));
    const [deleteUserDeviceLicenseModal, setDeleteUserDeviceLicenseModal] = useState(false);
    const [userDeviceLicenseToDelete, setUserDeviceLicenseToDelete] = useState({});
    const [rowToUpdate, setRowToUpdate] = useState({});
    const navigate = useNavigate();
    useEffect(()=> {
        handleSearch()
      }, [])

    async function handleSearch(){
        setIsLoading(true)
        try {
            const getProducts = () => axios({
                method: "GET",
                url: config.url.API + "/Products/get-products-and-licenses/" + user.accountId,
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "https://weinigportal.com/",
                  Authorization: "Bearer " + user.token,
                },
              });
            setProducts([])
            var response = await getProducts();

            if (response.data.statusCode === 401) {
              setIsLoading(false)
              setProducts([])
                AddStoreNotification(
                  "How did you get there?",
                  "danger",
                  "You're not authorized to be on that page."
                );
                navigate("/");
              } else if (response.data.statusCode === 204) {
                setIsLoading(false)
                setProducts([])
                AddStoreNotification(
                  "It's dangerous to go alone",
                  "info",
                  "There was no account matching that criteria"
                );
              } else if (response.data.statusCode === 422) {
                setIsLoading(false)
                setProducts([])
                AddStoreNotification(
                  "Error",
                  "danger",
                  "The server experienced an unknown error"
                );
              } else if (!!response.data) {
                setIsLoading(false)
                setProducts(response.data)
              }
          } catch (error) {
            setIsLoading(false)
            setProducts([])
            AddStoreNotification("Error","danger", error.message);
          }
    }

      function sendToWebpage(name){
        switch(name.toLowerCase()){
          case "cut":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "millvision":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "envision":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "mvlabeler":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "cadet":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "jobview":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          case "accumulator":
            window.open(
              'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
              '_blank'
            );
          break;
          default:
          break;
        }
    }

    function Row(productRow){
      const {row} = productRow;
      const [open, setOpen] = useState(false);

      return(
        <React.Fragment>
          <TableRow key={row.name} >
            <TableCell>
              <IconButton size="small" onClick={() => {setOpen(!open)}}>
                {open ? <KeyboardArrowUp sx={{color:"white"}}/> : <KeyboardArrowDown sx={{color:"white"}}/>}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row" sx={{fontSize:14.5, color:"white"}}>{row.name}</TableCell>
            <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.numberOfLicenses}</TableCell>
            <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.numberUsed}</TableCell>
            <TableCell align="right" sx={{fontSize:14.5, color:"#00963f"}}>{row.licenses.length}</TableCell> 
            {/* <TableCell align="right">
              <Button variant="contained" size="small" sx={{fontSize:14, textTransform:"none"}} onClick={(e) => {sendToWebpage(row.name)}}>manual</Button>
            </TableCell> */}
          </TableRow>
          <TableRow>
            <TableCell sx={{paddingBottom:0, paddingTop:0}} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{margin:1}}>
                  <Typography sx={{fontSize:16, color:"white"}}>Product Devices</Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}}>Product</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Device Name</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">License Active</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Activate/Deactivate</TableCell>
                        <TableCell sx={{fontSize:12, fontWeight:"bold", color:"white"}} align="right">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.licenses.map((license) => (
                        <TableRow key={license.hostName}>
                          <TableCell component="th" scope="row" sx={{fontSize:12, color:"white"}}>{license.product}</TableCell>
                          <TableCell align="right" sx={{fontSize:12, color:"white"}}>{license.hostName}</TableCell>
                          <TableCell align="right">
                            {license.active === "1" ? <Typography sx={{color:"#00963f", fontSize:12}}>yes</Typography> : <Typography sx={{color:"red", fontSize:12}}>no</Typography>}
                          </TableCell>
                          <TableCell align="right">
                            {license.active === "1" ? <Button onClick={() => {updateLicense(license, row)}} variant="contained" color="error" sx={{width:"75px", fontSize:12, textTransform:"none"}}>deactivate</Button> :
                             <Button onClick={() => {updateLicense(license, row)}} variant="contained" sx={{width:"75px", fontSize:12, textTransform:"none", background:"#00963f", '&:hover':{backgroundColor:"#007330"}}}>activate</Button>}
                          </TableCell>
                          <TableCell align="right">
                            <Button onClick={() => {setUserDeviceLicenseToDelete(license); setRowToUpdate(row); setDeleteUserDeviceLicenseModal(true);}} variant="contained" color="error" sx={{width:"75px", fontSize:12, textTransform:"none"}}>delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )
    }

    async function updateLicense(license, row){
      if(!!license.product && license.active !== null && !!license.hostName && !!license.productCode){
        var active = "0";
        var apiEndPoint = "/License/remove-license";
        var header = "A license for " + license.product + " was successfully deactivated";
        var message = license.product + " for user " + license.hostName + " was successfullly deactivated!";
        var addToLicenseCount = -1;

        if(license.active === "0"){
          active = "1";
          apiEndPoint = "/License/activate-license";
          header = "A license for " + license.product + " was successfully activated";
          message = license.product + " for user " + license.hostName + " was successfullly activated!";
          addToLicenseCount = 1;
        }

        try {
          setIsLoading(true);
          var licenseToUpdated = {}
          licenseToUpdated.UserName = user.username;
          licenseToUpdated.Product = license.product;
          licenseToUpdated.ProductCode = license.productCode;
          licenseToUpdated.AccountId = user.accountId;
          licenseToUpdated.Active = license.active.toString();
          licenseToUpdated.DateAdded = "";
          licenseToUpdated.HostName = license.hostName;
          licenseToUpdated.ClientId = "";
          licenseToUpdated.Code = license.productCode;
          licenseToUpdated.Software = "";
          const updateLicense = () => axios({
              method: "POST",
              url: config.url.API + apiEndPoint,
              data: JSON.stringify(licenseToUpdated),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.token,
                "Access-Control-Allow-Origin": "https://weinigportal.com/",
                "Access-Control-Allow-Methods": "*"
              },
            });
          var response = await updateLicense();
          if (response.status === 401) {
            setIsLoading(false)
              AddStoreNotification(
                "How did you get there?",
                "danger",
                "You're not authorized to be on that page."
              );
              navigate("/");
            } else if (response.status === 204) {
              setIsLoading(false)
              AddStoreNotification(
                "It's dangerous to go alone",
                "info",
                "There was no account matching that criteria"
              );
            } else if (response.status === 422) {
              setIsLoading(false)
              AddStoreNotification(
                "Error",
                "danger",
                "The server experienced an unknown error"
              );
            } else if (response.status === 202) {
              setIsLoading(false)
              license.active = active;
              row.numberUsed = row.numberUsed + addToLicenseCount;
              AddStoreNotification(
                header,
                "success",
                message
              );
            }
        } catch (error) {
          setIsLoading(false)
          AddStoreNotification("Error","danger", error.message);
        }   
      } 
    }

    async function deleteUserDeviceLicense(){
      var product = userDeviceLicenseToDelete.product;
      var hostName = userDeviceLicenseToDelete.hostName;
      try {
        setIsLoading(true);
        const deleteLicense = () => axios({
            method: "POST",
            url: config.url.API + "/License/delete-license",
            data: JSON.stringify(userDeviceLicenseToDelete),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
              "Access-Control-Allow-Methods": "*"
            },
          });
        var response = await deleteLicense();
        if (response.status === 401) {
          setIsLoading(false)
            AddStoreNotification(
              "How did you get there?",
              "danger",
              "You're not authorized to be on that page."
            );
            navigate("/");
          } else if (response.status === 204) {
            setIsLoading(false)
            AddStoreNotification(
              "It's dangerous to go alone",
              "info",
              "There was no account matching that criteria"
            );
          } else if (response.status === 422) {
            setIsLoading(false)
            AddStoreNotification(
              "Error",
              "danger",
              "The server experienced an unknown error"
            );
          } else if (response.status === 202) {
            const indexOfLicense = rowToUpdate.licenses.indexOf(userDeviceLicenseToDelete);
            const indexOfRow = products.indexOf(rowToUpdate);
            let numberActive = 0;
            if(indexOfLicense > -1 && indexOfRow > -1){
              rowToUpdate.licenses.splice(indexOfLicense, 1);
              rowToUpdate.licenses.forEach(license => {
                if(license.active === "1"){
                  numberActive++;
                }
              });
              rowToUpdate.numberUsed = numberActive;
              var newProductsArr = products;
              newProductsArr.splice(indexOfRow, 1, rowToUpdate);
              setProducts(newProductsArr);
            }
            setIsLoading(false)
            AddStoreNotification(
              "Successfully delete license",
              "success",
              "The " + product + " license was deleted for the product device " + hostName
            );
          }
      } catch (error) {
        setIsLoading(false)
        AddStoreNotification("Error","danger", error.message);
      }
    }

    return(
        <Box width="100%" height="calc(100% - 52px)" backgroundColor="#161616" display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" overflow="auto" >
          <Box width="97%" paddingBottom={"50px"}>
            <Paper sx={{ marginTop:"20px", position:"relative", background:"#202020", borderRadius:"0px"}} elevation={0} square>
                <Box display="flex" justifyContent="space-between" padding={"3px 0px 3px 0px"} >
                    <Typography padding={"15px 0px 15px 15px"} fontSize={17} color="white" fontWeight={"bold"} minWidth={"120px"}>{user.company} Software</Typography>
                </Box>
                {products.length !== 0 &&
                  <Box>
                    <Table>
                        <TableHead>
                            <TableRow sx={{background: "#1D1D1D", borderBottom:"2px solid white"}}>
                                <TableCell/>
                                <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}}>Product</TableCell>
                                <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Total Licenses</TableCell>
                                <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Active Licenses</TableCell>
                                <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Product Devices</TableCell>
                                {/* <TableCell sx={{fontSize:14.5, fontWeight:"bold", color:"white"}} align="right">Manual</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row) => (
                                <Row hover key={row.name} row={row}/>
                            ))}
                        </TableBody>
                    </Table>
                  </Box>
                }
            </Paper>
            {isLoading && <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}><CircularProgress sx={{margin:"20px 0px 0px 0px", color:"#00A551"}} size="30px"/></Box>}
          </Box>
          <Modal open={deleteUserDeviceLicenseModal} onClose={(e) => {setDeleteUserDeviceLicenseModal(false); setUserDeviceLicenseToDelete({});}} 
            sx={{backdropFilter: "blur(1px)"}}>
              <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"180px",
                backgroundColor:"#202020", padding:"4", borderRadius:"3px"}}>
                <Box>
                  <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={25} fontWeight={"500"} color={"white"}>Confirm Delete</Typography>
                  <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"500"} color={"white"}>Are you sure you want to delete the <strong>{userDeviceLicenseToDelete.product}</strong> license for <strong>{userDeviceLicenseToDelete.hostName}</strong>?</Typography>
                </Box>
                <Box sx={{float:"right"}}>
                  <Button variant="outlined" color="inherit" sx={{fontSize:14, textTransform:"none", boxShadow:0, background:"#eeeeee", color:"#62686e", '&:hover':{background:"#E3E3E3"}}} onClick={(e) => {setDeleteUserDeviceLicenseModal(false);}}>
                    Close
                  </Button>
                  <Button variant="contained" color="error" sx={{fontSize:14, textTransform:"none", marginRight:"10px", marginLeft:"15px", boxShadow:0}} onClick={(e) => {setDeleteUserDeviceLicenseModal(false); deleteUserDeviceLicense();}}>
                    Delete
                  </Button>
                </Box>
              </Box>
            </Modal>
          <Footer />
        </Box>
    )
}

export default Software;