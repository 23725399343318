import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import PerformanceIndicatorsImg from "../../../imgs/Pages/Products/PerformanceIndicators/PerformanceIndicators.png";
import PerformanceIndicatorsLogo from "../../../imgs/Pages/Products/PerformanceIndicators/PerformanceIndicatorsLogo.png";
import PerformanceIndicatorsImage1 from "../../../imgs/Pages/Products/PerformanceIndicators/PerformanceIndicatorsImage1.png";
import PerformanceIndicatorsImage2 from "../../../imgs/Pages/Products/PerformanceIndicators/PerformanceIndicatorsImage2.png";
import PerformanceIndicatorsImage3 from "../../../imgs/Pages/Products/PerformanceIndicators/PerformanceIndicatorsImage3.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const PerformanceIndicators = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", height:"60%"}}>
                    <Box sx={{width:"100%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"110%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="PI Logo" src={PerformanceIndicatorsLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}>Pi <br/>
                                <span style={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", background:"linear-gradient(to right, #0C009A, #D53043)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Performance Indicators</span></Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>Pi is your command post for at-a-glance production insights.</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"130px", fontWeight:"bold", '&:hover':{border:"2px solid #AAAAAA"}}}>Buy Modules</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#FFA800", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", background:"black"}}>
                        <img alt="PI Main" src={PerformanceIndicatorsImg} style={{height:"75%", width:"90%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1",
                         background:"linear-gradient(to right, #0C009A, #D53043)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>
                            Production Insights</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>View production related
                         metrics about different machines.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", 
                        background:"linear-gradient(to right, #0C009A, #D53043)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>
                            Customized Displays</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Pi’s dynamic displays help you display
                         curated information that will appeal distinctly to your individual machine operators. </Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", 
                        background:"linear-gradient(to right, #0C009A, #D53043)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>
                            Machine Metrics</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Show relevant statistical pie
                         graphs that monitor machine downtime and status changes.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span 
                    style={{fontSize:"37px", fontWeight:"bold", background:"linear-gradient(to right, #0C009A, #D53043)",
                     WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>
                    Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="PI Production" src={PerformanceIndicatorsImage1} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", fontWeight:"bold"}}><span 
                            style={{background:"linear-gradient(to right, #0C009A, #D53043)",
                            WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Production</span></Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Pi can show you different production metrics, such as Raw In, Product Out,
                             Yield, Piece Count, Machine State, and more.</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%"}}>
                            <Typography sx={{fontSize:"25px", fontWeight:"bold"}}><span 
                             style={{background:"linear-gradient(to right, #0C009A, #D53043)",
                             WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Customize</span></Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>With Pi you are able to customize your own view and create slides
                             that are displayed on a rotating basis. They continuously updated so you’re supplied with dynamic relevant information.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                             fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="PI Customize" src={PerformanceIndicatorsImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <img alt="PI Machine Monitoring" src={PerformanceIndicatorsImage3} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", fontWeight:"bold"}}><span 
                             style={{background:"linear-gradient(to right, #0C009A, #D53043)",
                             WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Machine Monitoring</span></Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>View information about your machines such as Production, Setup,
                             Standby, Idle, External Error and Error time.</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default PerformanceIndicators;