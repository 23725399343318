import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import PackImg from "../../../imgs/Pages/Products/Pack/Pack.png";
import PackLogo from "../../../imgs/Pages/Products/Pack/PackLogoSolid.png";
import PackImage1 from "../../../imgs/Pages/Products/Pack/PackImage1.png";
import PackImage2 from "../../../imgs/Pages/Products/Pack/PackImage2.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const Pack = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", height:"60%"}}>
                    <Box sx={{width:"100%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weining Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="Pack Logo" src={PackLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}><span style={{color:"#FFA800"}}>Pack.</span> <br/> Seamless Inventory Tracking.</Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>Define Inventory and Track Inventory Usage. <br/>
                                Its that simple.</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #FFA800"}}}>Buy now</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#FFA800", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", background:"black"}}>
                        <img alt="Pack Main" src={PackImg} style={{marginLeft:"25%", width:"75%", height:"95%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#FFA800"}}>Efficient</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Pack eliminates the need to
                         cycle count material and allows for custom manufacturing without building an excess inventory of finished items.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#FFA800"}}>Transparent</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Pack provides coherent summaries
                         and financial analysis reports on facility inventory. </Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#FFA800"}}>Simple</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Both user-friendly and lightweight,
                         Pack can run on Windows 7 or higher.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span style={{color:"#FFA800"}}>Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="Pack Inventory Summary" src={PackImage1} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#FFA800", fontWeight:"bold"}}>Inventory Summary</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Browse your inventory to engage with different metrics like
                             species, vendor, or price, to name a few. </Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #FFA800"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#FFA800", fontWeight:"bold"}}>Comprehensive Reporting</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Select and print current inventory, inventory activity history,
                             and financial analyses of inventory items.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                             fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #FFA800"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="Pack Comprehensive Reporting" src={PackImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default Pack;