import axios from "axios";
import React, { useState } from "react";
import logo from "../imgs/Logos/General Logos/icon.png";
import { AddStoreNotification } from "../Functions/StoreNotification";
import { useNavigate } from "react-router-dom";
import { config } from "../Functions/Constants";
import { Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import "../css/Login.css";

export default function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();

    var loggedIn = false;

    try {
      const loginAsync = () =>
        axios({
          method: "GET",
          url: config.url.API + "/Users/login/" + username + "/" + password,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://weinigportal.com/",
          },
        });

      var response = await loginAsync();

      if (response.data.status === 403) {
        AddStoreNotification(
          "Error Logging In",
          "danger",
          "This accounts role does not support access to the portal. Contact your admin."
        );
        setIsLoading(false);
      } else if (response.data.statusCode === 401) {
        AddStoreNotification(
          "Error Logging In",
          "danger",
          "Incorrect username or password combination"
        );
        setIsLoading(false);
      } else if (response.data.verified) {
        if (response.data.action === "update") {
          navigate("/reset-password-request");
        } else {
          localStorage.setItem("weinigPortalUser", JSON.stringify(response.data));
          setIsLoading(false);
          loggedIn = true;
        }
      } else {
        setIsLoading(false);
        AddStoreNotification(
          "Error Logging In",
          "danger",
          "The server encountered an unexpected error, please try again."
        );
      }
    } catch (error) {
      setIsLoading(false);
      AddStoreNotification(
        "Error Logging In",
        "danger",
        "Could not log you in. Please try again."
      );
    }

    if (loggedIn) {
      navigate("/home");
    }
  }

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{background:"linear-gradient(black,#141414)",  colorScheme:"dark"}}>
      <Box sx={{width:"470px", height:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", background:"transparent"}}>
        <Paper sx={{width:"100%", height:"575px", display:"flex", flexDirection:"column", alignItems:"center", background:"#2C2C2C", float:"top"}} elevation={1} square>
          <form onSubmit={(e) => handleSubmit(e)} style={{width:"100%"}}>
            <Box width="100%" display="flex" justifyContent="center">
              <img src={logo} alt="logo" className="loginLogo"/>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{margin:"45px 0px 15px 0px"}}>
              <Typography sx={{color:"white", fontSize:"30px"}}>Account Login</Typography>
              <TextField label="username" value={username} onChange={(e) => setUsername(e.target.value)} 
              variant="filled" size="small" className="login" sx={{margin:"40px 0px 20px 0px", background:"white", borderRadius:"4px"}}/>
              <TextField label="password" value={password} onChange={(e) => setPassword(e.target.value)} 
              variant="filled" size="small" type="password" className="login" sx={{margin:"0px 0px 20px 0px", background:"white", borderRadius:"4px"}}/>
              <Button variant="contained" type="submit" size="large" sx={{backgroundColor:"#00A551", borderRadius:"4px", boxShadow:"none", '&:hover':{backgroundColor:"#008537"}}}
              className="login" disabled={false}>Login</Button>
            </Box>
          </form>
          <a href={"/reset-password-request"} style={{textDecoration:"none"}}><Typography sx={{fontSize:"13px", color:"#00A551", '&:hover':{textDecoration:"underline"}}}>Reset Password</Typography></a>
        </Paper>
        <Box sx={{ marginTop:"25px", height:"50px"}}> 
          {isLoading && <CircularProgress sx={{color:"#00963f"}}/>}
        </Box>
      </Box>
    </Box>
  );
}