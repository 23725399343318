import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player'
import logo from "../../../imgs/Logos/General Logos/icon.png";
import MillVision from "../../../imgs/Pages/Products/MillVision/MillVision.png";
import MillVisionLogo from "../../../imgs/Pages/Products/MillVision/MillVisionLogoSolid.png";
import MillVisionImage1 from "../../../imgs/Pages/Products/MillVision/MillVisionImage1.png";
import MillVisionImage2 from "../../../imgs/Pages/Products/MillVision/MillVisionImage2.png";
import MillVisionImage3 from "../../../imgs/Pages/Products/MillVision/MillVisionImage3.png";
import MillVisionImage4 from "../../../imgs/Pages/Products/MillVision/MillVisionImage4.png";
import MillVisionImage5 from "../../../imgs/Pages/Products/MillVision/MillVisionImage5.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const Millvision = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="#F2F2F2" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", background:"black", height:"65%"}}>
                    <Box sx={{width:"110%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="MillVision Logo" src={MillVisionLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px"}}>The only post processor <br/> you'll ever <span style={{color:"#D53043"}}>need.</span></Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px"}}>MillVision takes in any data from any program that makes
                                 a bill of material and processes it so our machines can utilize it.</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #D53043"}}}>Buy now</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#D53043", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", marginLeft:"5%"}}>
                        <img alt="MillVision Main" src={MillVision} style={{width:"100%", height:"100%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D53043"}}>Versatile</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>MillVision can take in any data
                         or file, and you can define the columns and the incoming data.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D53043"}}>Reliable</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>A simple yet robust platform allows
                         for worry-free manufacturing that will work with the data you feed it.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D53043"}}>Lightweight</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>MillVision has a small memory footprint
                         and low CPU usage and will work with all machines installed with Windows 7 or later.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"#090909", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span style={{color:"#D53043"}}>Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"80%", marginBottom:"2%"}}>
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"50%"}}>
                            <img alt="MillVision Custom Configurations" src={MillVisionImage1} style={{width:"80%", height:'70%', borderRadius:"3px"}}/>
                            <Box sx={{width:"80%"}}>
                                <Typography sx={{fontSize:"25px", color:"#D53043", fontWeight:"bold", marginTop:"15px"}}>Custom Configurations</Typography>
                                <Typography sx={{fontSize:"19px", color:"white", marginTop:"10px"}}>MillVision can configure any exported cut lists from
                                 ERP systems or product design software packages.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"50%"}}>
                            <img alt="MillVision Data Manipulation" src={MillVisionImage2} style={{width:"80%", height:'70%', borderRadius:"3px"}}/>
                            <Box sx={{width:"80%"}}>
                                <Typography sx={{fontSize:"25px", color:"#D53043", fontWeight:"bold", marginTop:"15px"}}>Data Manipulation</Typography>
                                <Typography sx={{fontSize:"19px", color:"white", marginTop:"10px"}}>MillVision can reformat and modify incoming data to
                                 specifications from cut lists to match the requirements of the machines.</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"80%", marginBottom:"2%"}}>
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"50%"}}>
                            <img alt="MillVision Reporting" src={MillVisionImage3} style={{width:"80%", height:'70%', borderRadius:"3px"}}/>
                            <Box sx={{width:"80%"}}>
                                <Typography sx={{fontSize:"25px", color:"#D53043", fontWeight:"bold", marginTop:"15px"}}>Reporting</Typography>
                                <Typography sx={{fontSize:"19px", color:"white", marginTop:"10px"}}>MillVision has built-in reporting to track
                                 production metrics such as yield and order progress.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"50%"}}>
                            <img alt="MillVision Part Labeling" src={MillVisionImage4} style={{width:"80%", height:'70%', borderRadius:"3px"}}/>
                            <Box sx={{width:"80%"}}>
                                <Typography sx={{fontSize:"25px", color:"#D53043", fontWeight:"bold", marginTop:"15px"}}>Part Labeling</Typography>
                                <Typography sx={{fontSize:"19px", color:"white", marginTop:"10px"}}>MillVision can send data to the machines to be
                                 printed directly on processed wood using an inkjet printer or a standard label printer.</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"80%"}}>
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"50%"}}>
                            <img alt="MillVision Kickers" src={MillVisionImage5} style={{width:"80%", height:'70%', borderRadius:"3px"}}/>
                            <Box sx={{width:"80%"}}>
                                <Typography sx={{fontSize:"25px", color:"#D53043", fontWeight:"bold", marginTop:"15px"}}>Kickers</Typography>
                                <Typography sx={{fontSize:"19px", color:"white", marginTop:"10px"}}>MillVision has built-in reporting to track
                                 production metrics such as yield and order progress.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default Millvision;