import jwtDecode from "jwt-decode";
import {Navigate} from "react-router-dom";
import { AddStoreNotification } from "../Functions/StoreNotification";

const ProtectedRoute = ({
  
    redirect = "/login",
    children}) => {
    var user = JSON.parse(localStorage.getItem("weinigPortalUser"));
    if(user == undefined){
        return <Navigate to={redirect} replace/>;
    }
    let token = user["token"];
    let decodedToken = jwtDecode(token);
    if (parseInt(decodedToken.role) < 5) {
      AddStoreNotification(
        "Error Logging In",
        "danger",
        "This accounts role does not support access to the portal. Contact your admin."
      );
      localStorage.clear();
      return <Navigate to={redirect} replace/>;
    }else if(window.location.pathname.includes("admin-account-search")){
      if(parseInt(decodedToken.role) < 6){
        redirect = "/home";
        return <Navigate to={redirect} replace/>;
      }else{
        console.log("Valid Token");
        return children;
      }
    } else {
      console.log("Valid Token");
      return children;
    }
};

export default ProtectedRoute;