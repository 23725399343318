import axios from "axios";
import React, { useState } from "react";
import logo from "../imgs/Logos/General Logos/icon.png";
import { AddStoreNotification } from "../Functions/StoreNotification";
import { config } from "../Functions/Constants";
import { Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import "../css/Login.css";

export default function ResetRequest(props) {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
        var response = await axios({
          method: "GET",
          url: config.url.API + "/Users/reset-request/" + username,
          headers: {
            "Access-Control-Allow-Origin": "https://weinigportal.com/",
          },
        });
    
        if (response.data.statusCode === 202) {
            setIsLoading(false);
            AddStoreNotification(
            "Change Password Request",
            "success",
            "User has been found! Please check your email for the reset token."
            );
    
        } else {
            setIsLoading(false);
            AddStoreNotification(
            "Change Password Request",
            "danger",
            "We're sorry we were unable to complete your request at this time"
            );
    
        }
      } catch (err) {
        setIsLoading(false);
        AddStoreNotification("Error", "danger", err.message);
      }
  }

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{background:"linear-gradient(black,#141414)",  colorScheme:"dark"}}>
      <Box sx={{width:"470px", height:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", background:"transparent"}}>
        <Paper sx={{width:"100%", height:"575px", display:"flex", flexDirection:"column", alignItems:"center", background:"#2C2C2C", float:"top"}} elevation={1} square>
          <form onSubmit={(e) => handleSubmit(e)} style={{width:"100%"}}>
            <Box width="100%" display="flex" justifyContent="center">
              <img src={logo} alt="logo" className="loginLogo"/>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{margin:"45px 0px 15px 0px"}}>
              <Typography sx={{color:"white", fontSize:"30px"}}>Reset Password</Typography>
              
              <Box sx={{width:"65%"}}>
                <Typography sx={{color:"white", fontSize:"17px", margin:"35px 0px 10px 0px"}}>Enter username</Typography>
              </Box>
              <TextField label="username" value={username} onChange={(e) => setUsername(e.target.value)} 
              variant="filled" size="small" className="login" sx={{margin:"0px 0px 20px 0px", background:"white", borderRadius:"4px"}}/>
              <Button variant="contained" type="submit" size="large" sx={{backgroundColor:"#00A551", borderRadius:"4px", boxShadow:"none",
               '&:hover':{backgroundColor:"#008537"}}} className="login" disabled={false}>Submit</Button>
            </Box>
          </form></Paper>
        <Box sx={{ marginTop:"25px", height:"50px"}}> 
          {isLoading && <CircularProgress sx={{color:"#00963f"}}/>}
        </Box>
      </Box>
    </Box>
  );
}