import { AppBar, Box, Button, styled, Typography, Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import avatar from "../../imgs/Logos/General Logos/avatar.png"
import HomepageMainImage from "../../imgs/Pages/Homepage/backgroundImageHome.png";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const ProfileIcon = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "20px",
    alignItems: "center"
}));

const Homepage = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const loggedIn = Boolean((localStorage.getItem('weinigPortalUser') != null));
    const navigate = useNavigate();

    const click = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const close = () => {
        setAnchorEl(null);
    }
    const Logout = () =>{
        localStorage.removeItem("weinigPortalUser")
        navigate("/login")
    }

    return(
        <Box width="100%" height="100% " backgroundColor="black" overflow="auto">
            <AppBar sx={{width:"100%", position:"absolute", background:"#00000000"}} elevation={0}>
                {loggedIn && (
                        <ProfileIcon sx={{marginLeft:"auto", marginRight:"20px", marginTop:"2px", marginBottom:"2px"}}>
                            <IconButton onClick={click} aria-controls={open ? 'menu': undefined}
                            aria-haspopup="true" aria-expanded={open ? 'true': undefined}>
                                <Avatar src={avatar} sx={{width:"32px", height:"32px"}}/>
                            </IconButton>
                        </ProfileIcon>
                )}
                {loggedIn && (
                    <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={close}
                    onClick={close}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuItem onClick={Logout}>Logout</MenuItem>
                    </Menu>
                )}
            </AppBar>
            <Box sx={{width:"100%", height:"calc(72% - 55px)", background:"black", display:"flex", justifyContent:"space-between"}}>
                <Box sx={{marginLeft:"12%", width:"45%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <Box sx={{ float:"right"}}>
                        <Typography sx={{color:"#ffffff", fontSize:"270%", lineHeight:"1.1"}}>Welcome to your</Typography>
                        <Typography sx={{color:"#ffffff", fontSize:"270%", marginBottom:"30px", lineHeight:"1.1"}}><span style={{color:"#00A551"}}>Weinig </span> <span style={{color:"#FF6600"}}>Holz-Her </span><br/> Software Portal</Typography>
                        <Typography sx={{color:"#ffffff", fontSize:"116%", marginBottom:"30px", lineHeight:"1.3", width:"85%"}}>We have everything you need to know how to use your Weinig Holz-Her software and manage your account and users.</Typography>
                        <Button onClick={(e) => {navigate("/store")}} variant="outlined" size="large" sx={{color:"#ffffff", borderColor:"#00A551", fontSize:"17px", borderRadius:"10px", textTransform:"none",'&:hover':{backgroundColor:"#00A551", color:"white", borderColor:"#00A551"}}}>
                        View Our Lineup</Button>
                    </Box>
                </Box>
                <Box sx={{width:"65%"}}>
                    <img alt="Homepage Main" src={HomepageMainImage} style={{width:"100%", height:"100%"}}/>
                </Box>
            </Box>
            
            <Box sx={{height:"calc(28% - 20px)", background:"black", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", marginLeft:"12%", marginRight:"12%", marginBottom:"20px"}}>
                <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"33%", margin:"0% 5% 0% 0%"}}>
                        <Typography sx={{color:"#00A551", fontSize:"165%", lineHeight:"1.1"}}>Software</Typography>
                        <Typography sx={{color:"#ffffff", fontSize:"98%", lineHeight:"1.4", marginTop:"20px"}} align="center">Keep track of your software,
                         see how many licenses you have available and how many are active, and browse Weinig Holz-Her software products.</Typography>
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"33%", margin:"0% 5% 0% 5%"}}>
                        <Typography sx={{color:"#00A551", fontSize:"165%", lineHeight:"1.1"}}>Documentation</Typography>
                        <Typography sx={{color:"#ffffff", fontSize:"98%", lineHeight:"1.4", marginTop:"20px"}} align="center">Browse manuals and documentation
                         for your software and keep up to date with the new changes.</Typography>
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"33%", margin:"0% 0% 0% 5%"}}>
                        <Typography sx={{color:"#00A551", fontSize:"165%", lineHeight:"1.1"}}>Accounts</Typography>
                        <Typography sx={{color:"#ffffff", fontSize:"98%", lineHeight:"1.4", marginTop:"20px"}} align="center">Update, add, or delete any
                         account information, company information, users, and passwords. </Typography>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

export default Homepage;