import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import MAPSImg from "../../../imgs/Pages/Products/MAPS/MAPS.png";
import MAPSLogo from "../../../imgs/Pages/Products/MAPS/MAPSLogoNewest.png";
import MAPSImage1 from "../../../imgs/Pages/Products/MAPS/MillVisionImage1.png";
import MAPSImage2 from "../../../imgs/Pages/Products/MAPS/AccumulatorImage1.png";
import MAPSImage3 from "../../../imgs/Pages/Products/MAPS/ReportingImage1.png";
import MillVisionLogo from "../../../imgs/Logos/MillVision/MillVisionLogo.png";
import AccumulatorLogo from "../../../imgs/Logos/Accumulator/AccumulatorLogo.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const MAPS = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", height:"60%"}}>
                    <Box sx={{width:"100%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"110%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="MAPS Logo" src={MAPSLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}><span style={{}}>MAP Suite.</span><br/> Machine Automation Platform.</Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>The new platform for <span style={{color:"#00A551"}}>Weinig </span> <span style={{color:"#F26722"}}>Holz-Her </span> software</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"130px", fontWeight:"bold", '&:hover':{border:"2px solid #AAAAAA"}}}>Buy Modules</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#FFA800", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", background:"black"}}>
                        <img alt="MAPS Main" src={MAPSImg} style={{height:"55%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"black"}}>Cutting Edge</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>MAPS contains Weinig's current
                         software options you know with improved capabilities and new offerings with greater intelligence across the board. </Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"black"}}>Intuitive Automation</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Eliminate fingerprints in your process.
                        MAPs interlinks your current systems,  so machines communicate and drive intelligent automation.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"black"}}>Built to Last</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Available for all Weinig Holz-Her
                         customers, MAPs is here to grow with you at every stage of business progression.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover <span style={{color:"white"}}>MAPS</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="MAPS MillVision" src={MAPSImage1} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <img alt="MAPS MillVision Logo" src={MillVisionLogo} style={{width:"70px", height:"70px"}} />
                            <Typography sx={{fontSize:"25px", color:"white", fontWeight:"bold"}}>MillVision</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>In MAPS, MillVision is split into MillVision Pro (for cutting and ripping) 
                            and MillVision Lite (for cutting only).</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #AAAAAA"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%"}}>
                            <img alt="MAPS Accumulator Logo" src={AccumulatorLogo}style={{width:"70px", height:"70px"}} />
                            <Typography sx={{fontSize:"25px", color:"white", fontWeight:"bold"}}>Accumulator</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>With Accumulator you can process and gather your flooring orders
                                in a method that allows you to see real time production, feedback, and create packing lists.</Typography>
                            <Button variant="outlined" size="small" sx={{color:"black" ,background:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                             fontSize:"15px", width:"110px", marginTop:"40px",marginRight:"15px", fontWeight:"bold", '&:hover':{border:"2px solid #E6E6E6", background:"#E6E6E6"}}}
                             onClick={(e) => {navigate("/accumulator")}}>Learn more</Button>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                             fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #AAAAAA"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="MAPS Accumulator" src={MAPSImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <img alt="MAPS Reporting" src={MAPSImage3} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"white", fontWeight:"bold"}}>Reporting</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>View different metrics about your production such as, good wood,
                            fixed length, yield percentage, waste and more.</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #AAAAAA"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default MAPS;