import { Button, CircularProgress, Modal, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { AddStoreNotification } from "../../Functions/StoreNotification";
import { useNavigate } from "react-router-dom";
import { config } from "../../Functions/Constants";
import SubmitPasswordResetRequest from "../../Functions/PasswordReset";
import Footer from "../Footer";
    
const UserProfileNew = () => {
const [users, setUsers] = useState([]);
const navigate = useNavigate();
const [user, setUser] = useState(JSON.parse(localStorage.getItem("weinigPortalUser")));
const [isLoading, setIsLoading] = useState(false);
const [createModalOpen, setCreateModalOpen] = useState(false);
const [passwordTextFieldColor, setPasswordTextFieldColor] = useState("primary");
const [passwordWarning, setPasswordWarning] = useState("");
const [deleteCheckModal, setDeleteCheckModal] = useState(false);
const [userToDelete, setUserToDelete] = useState({});
const [passwordBorderBottom, setPasswordBorderBottom] = useState("1px solid white");
const [newUser, setNewUser] = useState({
        username: "",
        emailAddress: "",
        password: "",
        confirmPassword: ""
    });


    useEffect(()=> {
        GetUsers(user)
    }, [""])

    async function GetUsers(user) {
        setIsLoading(true)
        user.SfId = user.accountId;
        var response = await axios({
            method: "GET",
            url: config.url.API + "/Users/get-users/" + user.SfId,
            headers: {
              Authorization: "Bearer " + user.token,
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
            },
          });

        if (response.data.statusCode === 401) {
            setIsLoading(false)
            AddStoreNotification(
            "How did you get there?",
            "danger",
            "You're not authorized to be on that page."
            );
            navigate("/");
        } else if (response.data.statusCode === 204) {
            setIsLoading(false)
            AddStoreNotification(
            "It's dangerous to go alone",
            "info",
            ""
            );
        } else if (response.data.statusCode === 422) {
            setIsLoading(false)
            AddStoreNotification(
            "Error",
            "danger",
            "The server experienced an unknown error"
            );
        } else if (response.status === 200) {
            setIsLoading(false)
        setUsers(response.data);
        }
    };

    async function CreateUser() {
        AddStoreNotification("Creating new User", "default", "Creating a new user");
        setCreateModalOpen(false);
        let type = ""
        let message = ""
        if(newUser.password !== ""){
            if(newUser.password === newUser.confirmPassword){
                newUser.accountId = user.accountId;
                newUser.company = user.company;
                newUser.type = "User";
                newUser.streetAddress = user.streetAddress;
                newUser.city = user.city;
                newUser.state = user.state;
                newUser.zipCode = user.zipCode;
                newUser.action = user.action;
                newUser.fullyQualifiedAddress = user.fullyQualifiedAddress;
                newUser.phoneNumber = user.phoneNumber;
                newUser.primaryContact = user.primaryContact;
                newUser.status = user.status;
                newUser.verified = user.verified;
                newUser.token = user.token;

                var response = await axios({
                method: "post",
                url: config.url.API + "/Users/create-user",
                data: JSON.stringify(newUser),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + newUser.token,
                    "Access-Control-Allow-Origin": "https://weinigportal.com/",
                },
                });
            
                type = "danger";
            
                if (
                response.data.statusCode !== "409" ||
                response.data.statusCode !== "401"
                ) {
                GetUsers(user)
                setNewUser({username: "", emailAddress: "", password: "", confirmPassword: ""});
                type = "success";
                }
            
                message = "Could not add user!";
                if (type === "success") message = "User was Created!";
                AddStoreNotification("User Created", type, message);
            }else{
                type = "warning"
                message = "passwords do not match"
                AddStoreNotification("Error Creating User", type, message);
            }
        }else{
            type = "warning"
            message = "password cannot be empty"
            AddStoreNotification("Error Creating User", type, message);
        }
    }

    async function handleUserUpdate(e, userToUpdate){
        e.preventDefault();
        try {
            const updateUsers = () => axios({
                method: "PUT",
                url: config.url.API + "/Users/update-user",
                data: JSON.stringify(userToUpdate),
                headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://weinigportal.com/",
                Authorization: "Bearer " + user.token,
                },
            });
            var response = await updateUsers();

            if (response.data.statusCode === 401) {
                AddStoreNotification(
                "How did you get there?",
                "danger",
                "You're not authorized to be on that page."
                );
                navigate("/");
            } else if (response.data.statusCode === 204) {
                AddStoreNotification(
                "It's dangerous to go alone",
                "info",
                ""
                );
            } else if (response.data.statusCode === 422) {
                AddStoreNotification(
                "Error",
                "danger",
                "The server experienced an unknown error"
                );
            } else if (response.data.statusCode === 200) {
                GetUsers(user);
                AddStoreNotification(
                "Success",
                "success",
                "User information has been successfuly updated"
                );
            }
        } catch (error) {
            AddStoreNotification("Error","danger", error.message);
        }
    }

    async function DeleteUser() {
        var DeletedUser = userToDelete;
        try {
          var response = await axios({
            method: "delete",
            url: config.url.API + "/Users/delete-user",
            data: JSON.stringify(DeletedUser),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
              "Access-Control-Allow-Origin": "https://weinigportal.com/",
              "Access-Control-Allow-Methods": "*",
            },
          });
    
          let type = "danger";
    
          if (response.status === 200) {
            setDeleteCheckModal(false)
            type = "success";
            const index = users.indexOf(DeletedUser);
            var newArr = [...users];
            if (index > -1) {
                newArr.splice(index, 1)
                setUsers(newArr)
            }
        } else if (response.status === 404) {
            setDeleteCheckModal(false)
            type = "danger";
        }
            let message = "Could not delete user!";
            if (type === "success") message = "User has been removed!";
            AddStoreNotification("Deleted User", type, message);
        } catch (err) {
            setDeleteCheckModal(false)
            AddStoreNotification("Error", "danger", err.message);
        }
      }

    function handleUserChange(e, userIndex) {
        let newArr = [...users];

        switch (e.target.name) {
        case "username":
            newArr[userIndex].username = e.target.value;
            break;
        case "company":
            newArr[userIndex].company = e.target.value;
            break;
        case "phone":
            newArr[userIndex].phoneNumber = e.target.value;
            break;
        case "email":
            newArr[userIndex].emailAddress = e.target.value;
            break;
        case "contact":
            newArr[userIndex].primaryContact = e.target.value;
            break;
        case "id":
            newArr[userIndex].accountId = e.target.value;
            break;
        case "address":
            newArr[userIndex].streetAddress = e.target.value;
            break;
        case "city":
            newArr[userIndex].city = e.target.value;
            break;
        case "state":
            newArr[userIndex].state = e.target.value;
            break;
        case "zip":
            newArr[userIndex].zipCode = e.target.value;
            break;
        case "accountType":
            newArr[userIndex].type = e.target.value;
            break;
        case "accessLevel":
            newArr[userIndex].accessLevel = e.target.value;
            break;
        default:
            break;
        }
        setUsers(newArr)
    }

    function handleNewUserUpdate(e, userToUpdate) {
        switch (e.target.name) {
        case "username":
            setNewUser({
                ...userToUpdate,
                username: e.target.value});
            break;
        case "email":
            setNewUser({
                ...userToUpdate,
                emailAddress: e.target.value});
            break;
        case "password":
            if(e.target.value.length < 8){
                setPasswordTextFieldColor("error");
                setPasswordBorderBottom("1px solid red");
                setPasswordWarning("* password must be at least 8 characters");
            }else{
              if(e.target.value !== newUser.confirmPassword){
                setPasswordTextFieldColor("error");
                setPasswordBorderBottom("1px solid red");
                setPasswordWarning("* passwords do not match");
              }else{
                setPasswordTextFieldColor("primary")
                setPasswordBorderBottom("1px solid white");
                setPasswordWarning("")
              }
            }
            setNewUser({
                ...userToUpdate,
                password: e.target.value});
            break;
        case "confirmPassword":
            if(e.target.value !== newUser.password){
                if(newUser.password < 8){
                    setPasswordTextFieldColor("error");
                    setPasswordBorderBottom("1px solid red");
                    setPasswordWarning("* password must be at least 8 characters");
                }else{
                    setPasswordTextFieldColor("error")
                    setPasswordBorderBottom("1px solid red");
                    setPasswordWarning("* passwords do not match")
                }
            }else{
                if(newUser.password < 8){
                    setPasswordTextFieldColor("error");
                    setPasswordBorderBottom("1px solid red");
                    setPasswordWarning("* password must be at least 8 characters");
                }else{
                    setPasswordTextFieldColor("primary")
                    setPasswordBorderBottom("1px solid white");
                    setPasswordWarning("")
                }
            }
            setNewUser({
                ...userToUpdate,
                confirmPassword: e.target.value});
            break;
        default:
            break;
        }
    }
    return(
        <Box width="100%" height="calc(100% - 52px)" backgroundColor="#161616" display="flex" alignItems="center" flexDirection="column" justifyContent="space-between" overflow="auto" >
            <Box width="97%" paddingBottom="50px">
                <Paper sx={{marginTop:"20px", overflow:"hidden", background:"#202020", borderRadius:"0px"}} elevation={0} square>
                    <Box display="flex" alignItems="center" margin={"8px 15px 8px 15px"}>
                        <Typography flex={1} minWidth={"120px"} fontSize={17} color={"white"}>{user.company} Users</Typography>
                        <Button variant="contained" sx={{fontSize:14,fontWeight:"bold", float:"right", textTransform:"none"}} onClick={(e) => {setCreateModalOpen(true)}}>
                            Create New User
                        </Button>
                    </Box>
                </Paper>

                {users.length !== 0 &&
                    <Paper sx={{marginTop:"15px", overflow:"hidden",background:"#202020", borderRadius:"0px"}} elevation={0} square>
                        <Box>
                            {users.map((user, index) => (
                                <Box style={{alignItems:'center', flex:10}}>
                                    <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={14.5} color={"white"}>{user.username} user details</Typography>
                                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                                        <TextField name="username" size="small" variant="filled" label="username" value={user.username} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="company" size="small" variant="filled" label="company" value={user.company} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="phone" size="small" variant="filled" label="Phone" value={user.phoneNumber} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="email" size="small" variant="filled" label="Email" value={user.emailAddress} 
                                        onChange={(e) =>{handleUserChange(e, index)}} sx={{width:"25%", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                                    </Box>
                                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                                        <TextField name="contact" size="small" variant="filled" label="Contact"value={user.primaryContact} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="address" size="small" variant="filled" label="Address" value={user.streetAddress} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="city" size="small" variant="filled" label="City" value={user.city} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="state" size="small" variant="filled" label="State" value={user.state} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"25%", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                                    </Box>
                                    <Box display="flex" margin={"15px 15px 15px 15px"}>
                                        <TextField name="zip" size="small" variant="filled" label="Zip" value={user.zipCode} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"33%", marginRight:"15px", borderBottom: "1px solid white"}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                                        <TextField name="accountType" size="small" variant="filled" label="Account Type" disabled value={user.type} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"33%", marginRight:"15px", borderBottom: "1px solid white", color:"white", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "gray"}}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"gray"}}}></TextField>

                                        <TextField name="accessLevel" size="small" variant="filled" label="Access Level" disabled value={user.accessLevel} 
                                        onChange={(e) =>{handleUserChange(e, index)}} 
                                        sx={{width:"33%", borderBottom: "1px solid white", color:"white", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "gray"}}} 
                                        InputProps={{style: {fontSize:14.5, color:"white", disableUnderline:true}, disableUnderline:true}} 
                                        InputLabelProps={{style: {fontSize:14.5, color:"gray"}}}></TextField>
                                        
                                    </Box>
                                    <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                                        <Box sx={{width:"100%"}}>
                                            <Button variant="contained" color="error" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {setDeleteCheckModal(true); setUserToDelete(user)}}>
                                                Delete
                                            </Button>
                                            <Button variant="contained" sx={{fontSize:14, float:"right", marginRight:"15px", textTransform:"none", background:"#00963f", '&:hover':{backgroundColor:"#007330"}}} onClick={(e) => {SubmitPasswordResetRequest(user.username)}}>
                                                Reset Password
                                            </Button>
                                            <Button variant="contained" sx={{fontSize:14, float:"right", marginRight:"15px", textTransform:"none"}} onClick={(e) => {handleUserUpdate(e, user)}}>
                                                Update
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Paper>
                }
                {isLoading && <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}><CircularProgress size="30px" sx={{marginTop:"15px", color:"#00A551"}}/></Box>}
            </Box>
            

            <Modal open={createModalOpen} onClose={(e) => {setCreateModalOpen(false); setNewUser({username: "", emailAddress: "", password: "", confirmPassword: ""})}} 
            sx={{backdropFilter: "blur(0px)"}}>
              <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"225px",
                backgroundColor:"#202020", padding:"4", borderRadius:"5px"}}>
                <Typography margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={17}>Create new user</Typography>
                <Box display="flex" margin={"15px 15px 15px 15px"}>
                    <TextField name="username" size="small" variant="filled" label="Username" value={newUser.username} 
                    onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                    sx={{width:"50%", marginRight:"15px", borderBottom: "1px solid white"}} 
                    InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                    InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                    <TextField name="email" size="small" variant="filled" label="Email" value={newUser.emailAddress} 
                    onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                    sx={{width:"50%", borderBottom: "1px solid white"}} 
                    InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                    InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                    </Box>
                <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                    <TextField name="password" size="small" variant="filled" label="Password" type="password" color={passwordTextFieldColor} value={newUser.password} 
                    onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                    sx={{width:"50%", marginRight:"15px", borderBottom: passwordBorderBottom}} 
                    InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                    InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>

                    <TextField name="confirmPassword" size="small" variant="filled" label="Confirm Password" type="password" color={passwordTextFieldColor} value={newUser.confirmPassword} 
                    onChange={(e) =>{handleNewUserUpdate(e, newUser)}} 
                    sx={{width:"50%", borderBottom: passwordBorderBottom}} 
                    InputProps={{style: {fontSize:14.5, color:"white"}, disableUnderline:true}} 
                    InputLabelProps={{style: {fontSize:14.5, color:"#B6B6B6"}}}></TextField>
                 </Box>
                <Box display="flex" margin={"15px 15px 15px 15px"} alignItems={"center"}>
                    <Typography fontSize={12} sx={{width:"50%", color:"red"}}>{passwordWarning}</Typography>
                    <Box sx={{width:"50%"}}>
                        <Button variant="contained" sx={{fontSize:14, float:"right", textTransform:"none"}} onClick={(e) => {CreateUser()}}>
                            Create User
                        </Button>
                    </Box>
                </Box>
              </Box>
            </Modal>

            <Modal open={deleteCheckModal} onClose={(e) => {setDeleteCheckModal(false)}} 
            sx={{backdropFilter: "blur(1px)"}}>
              <Box sx={{position:"absolute", top:"30%", left:"50%", transform: "translate(-50%, -50%)", width:"25%", height:"160px",
                backgroundColor:"#202020", padding:"4", borderRadius:"3px"}}>
                <Box>
                  <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={25} fontWeight={"500"} color={"white"}>Confirm Delete</Typography>
                  <Typography flex={1} margin={"15px 15px 15px 15px"} minWidth={"120px"} fontSize={15} fontWeight={"500"}color={"white"}>Are you sure you want to delete user <strong>{userToDelete.username}</strong>?</Typography>
                </Box>
                <Box sx={{float:"right"}}>
                  <Button variant="contained" color="inherit" sx={{fontSize:14, textTransform:"none", boxShadow:0, background:"#eeeeee", color:"#62686e", '&:hover':{background:"#E3E3E3"}}} onClick={(e) => {setDeleteCheckModal(false)}}>
                    Close
                  </Button>
                  <Button variant="contained" color="error" sx={{fontSize:14, textTransform:"none", marginRight:"10px", marginLeft:"15px", boxShadow:0}} onClick={(e) => {DeleteUser()}}>
                    Delete
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Footer />
        </Box>
    )
}
export default UserProfileNew;