import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import EnVision from "../../../imgs/Pages/Products/EnVision/EnVisionLight.png";
import EnVisionLogo from "../../../imgs/Pages/Products/EnVision/EnVisionLogoSolid.png";
import EnVisionImage1 from "../../../imgs/Pages/Products/EnVision/EnVisionImage1.png";
import EnVisionImage2 from "../../../imgs/Pages/Products/EnVision/EnVisionImage2.png";
import EnVisionImage3 from "../../../imgs/Pages/Products/EnVision/EnVisionImage3.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const Envision = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="#F2F2F2" display="flex" flexDirection="column" justifyContent="space-between" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", background:"black", height:"65%"}}>
                    <Box sx={{width:"110%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="EnVision Logo" src={EnVisionLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"#00A551"}}>EnVision.</Typography>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1"}}>The Possibilities.</Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px"}}>Software to ensure maximum flexibility for on-demand production. <br/> Everything for solid wood processing.
                                Everything from a single source. <br/> Everything 100%</Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #00A551"}}}>Buy Now</Button> */}
                                    <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white", color:"black", borderRadius:"10px", textTransform:"none", boxShadow:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{background:"#00A551", boxShadow:"none"}}}>Demo</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", background:"black", display:"flex", alignItems:"center", justifyContent:"center",}}>
                        <img alt="EnVision Main" src={EnVision} style={{width:"87%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#00A551"}}>Automate</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>With EnVision, you can create
                         templates for all your products and drive efficiency.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#00A551"}}>Parametric</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>True parametric joinery for your
                         entire product line, including door stiles, rails, inserts, all adjusted simultaneously.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#00A551"}}>Panel Processing</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Design parametric cabinets for
                         simple and easy changes and solid wood door components.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"#000000", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span style={{color:"#00A551"}}>Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="EnVision Visual Browsing" src={EnVisionImage1} style={{width:"60%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#00A551", fontWeight:"bold"}}>Visual Browsing</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Users are able to create a personalized catalog of templates
                             that fit their product catalog. Templates are assigned a simple visual graphic (also defined by the user) so that selection is
                              user-friendly.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #00A551"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%", textAlign:"Left", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#00A551", fontWeight:"bold"}}>Product Editing</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Once a template is selected, the user can edit variables on
                             that product, such as component dimensions, material, quantity, etc. Users have complete control over which variables are editable
                              on each template.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #00A551"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="EnVision Product Editing" src={EnVisionImage3} style={{width:"60%", borderRadius:"3px"}}/>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <img alt="EnVision Bill of Material Generation" src={EnVisionImage2} style={{width:"60%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%", width:"50%"}}>
                            <Typography sx={{fontSize:"25px", color:"#00A551", fontWeight:"bold"}}>Bill of Material Generation</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Once the user defines variables for the part, EnVision will provide
                             a complete Bill of Materials visual preview.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none", fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #00A551"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default Envision;