import { AppBar, Box, styled, Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import avatar from "../imgs/Logos/General Logos/avatar.png"
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "../css/Navbar.css";

const ProfileIcon = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "20px",
    alignItems: "center"
}));

const NavBarTop = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const loggedIn = Boolean((localStorage.getItem('weinigPortalUser') != null));
    const navigate = useNavigate();

    const click = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const close = () => {
        setAnchorEl(null);
    }
    const Logout = () =>{
        localStorage.removeItem("weinigPortalUser")
        navigate("/login")
    }
    return(
        <AppBar sx={{background:"#000000", position:"relative", width:"100%"}} elevation={0} >
            {loggedIn && (
                <ProfileIcon sx={{marginLeft:"auto", marginRight:"20px", marginTop:"2px", marginBottom:"2px"}}>
                    <IconButton onClick={click} aria-controls={open ? 'menu': undefined}
                    aria-haspopup="true" aria-expanded={open ? 'true': undefined}>
                        <Avatar src={avatar} sx={{width:"32px", height:"32px"}}/>
                    </IconButton>
                </ProfileIcon>
            )}
            {loggedIn && (
            <Menu
            id="menu"
            anchorEl={anchorEl}
            open={open}
            onClose={close}
            onClick={close}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
            )}
        </AppBar>
    );

}

export default NavBarTop;