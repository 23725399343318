import { AddStoreNotification } from "./StoreNotification";
import axios from "axios";
import { config } from "./Constants";

export default async function SubmitPasswordResetRequest(username) {
  try {
    var response = await axios({
      method: "GET",
      url: config.url.API + "/Users/reset-request/" + username,
      headers: {
        "Access-Control-Allow-Origin": "https://weinigportal.com/",
      },
    });

    if (response.data.statusCode === 202) {
      AddStoreNotification(
        "Change Password Request",
        "success",
        "User has been found! Please check your email for the reset token."
      );

      return "ok";
    } else {
      AddStoreNotification(
        "Change Password Request",
        "danger",
        "We're sorry we were unable to complete your request at this time"
      );

      return "not found";
    }
  } catch (err) {
    AddStoreNotification("Error", "danger", err.message);
  }
}