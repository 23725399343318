import axios from "axios";
import React, { useState } from "react";
import logo from "../imgs/Logos/General Logos/icon.png";
import { AddStoreNotification } from "../Functions/StoreNotification";
import { config } from "../Functions/Constants";
import { Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";

export default function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let { token } = useParams();
  let { username } = useParams();
  let { email } = useParams();

  async function handleSubmit(e) {
    e.preventDefault();
    if(password.length > 7 && password === confirmPassword){
        setIsLoading(true);
        try {
        var response = await axios({
            method: "Put",
            url: config.url.API + "/Users/reset-password",
            data: JSON.stringify({ username: username, password: password, token: token, email: email}),
            headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://weinigportal.com/",
            Authorization: "Bearer " + token,
            },
        });

        if (response.data.statusCode === 202) {
            setIsLoading(false);
            AddStoreNotification(
            "Updated Password",
            "success",
            "Password has been updated!"
            );
            navigate("/");
        } else if (response.data.statusCode === 401) {
            setIsLoading(false);
            AddStoreNotification(
            "Error Updating Password",
            "danger",
            "The Token you entered has either expired, does not exist or has already been used. Please try again or request a new token."
            );
            navigate("/");
        }
        } catch (err) {
            setIsLoading(false);
            AddStoreNotification(
            "Error",
            "danger",
            "There was an unexpected error processing your request, please try again"
            );
        }
        
    }else{
        AddStoreNotification(
        "New Password Enter Error",
        "danger",
        "Please makes sure your passwords match and are at least 8 characters"
        );
    }
  }

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{background:"linear-gradient(black,#141414)",  colorScheme:"dark"}}>
      <Box sx={{width:"470px", height:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", background:"transparent"}}>
        <Paper sx={{width:"100%", height:"575px", display:"flex", flexDirection:"column", alignItems:"center", background:"#2C2C2C", float:"top"}} elevation={1} square>
          <form onSubmit={(e) => handleSubmit(e)} style={{width:"100%"}}>
            <Box width="100%" display="flex" justifyContent="center">
              <img src={logo} alt="logo" className="loginLogo"/>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{margin:"45px 0px 15px 0px"}}>
              <Typography sx={{color:"white", fontSize:"30px"}}>Reset Password</Typography>
              <TextField label="new password" value={password} onChange={(e) => setPassword(e.target.value)} 
              variant="filled" size="small" type="password" className="login" sx={{margin:"40px 0px 10px 0px", background:"white", borderRadius:"4px"}}/>
              <TextField label="confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} 
              variant="filled" size="small" type="password" className="login" sx={{margin:"10px 0px 30px 0px", background:"white", borderRadius:"4px"}}/>
              <Button variant="contained" type="submit" size="large" sx={{backgroundColor:"#00A551", borderRadius:"4px", boxShadow:"none",
               '&:hover':{backgroundColor:"#008537"}}} className="login" disabled={false}>Submit</Button>
            </Box>
          </form></Paper>
        <Box sx={{ marginTop:"25px", height:"50px"}}> 
          {isLoading && <CircularProgress sx={{color:"#00963f"}}/>}
        </Box>
      </Box>
    </Box>
  );
}