import React from "react";
import logo from "../imgs/Logos/General Logos/icon.png";
import MapsReporting from "../imgs/Pages/Landing/mapsReporting.png";
import MAPnbg from "../imgs/Logos/MAPS/MAPSLogoNewest.png";
import { useNavigate } from "react-router-dom";
import { Box, Button,Typography } from "@mui/material";
import "../css/Login.css";
import { ArrowUpward } from "@mui/icons-material";

export default function Landing() {
    const navigate = useNavigate();

    return (
        <Box width="100%" height="100%" overflow="auto" sx={{background:"linear-gradient(black,#141414)",  colorScheme:"dark"}}>
        <Box sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <img src={logo} width="130" alt="HolzHerWeinigLogo" style={{margin:"0px 0px 0px 20px"}}/>
            <Button onClick={(e) => {navigate("/login")}} sx={{height:"48px", color:"white", background:"transparent", textTransform:"none", margin:"15px 15px 0px 0px", border:"1px solid white", '&:hover':{backgroundColor:"white", color:"black"}}}>Get Started</Button>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Typography sx={{color:"white", fontSize:"23px", marginTop:"4.5%"}}>Weinig Holz-Her Software Portal</Typography>
            <Typography sx={{color:"white", fontSize:"40px", marginTop:"10px"}} align="center">Your Portal into the <span style={{color:"#00A551"}}>Weinig </span> <span style={{color:"#FF6600"}}>Holz-Her </span><br/>Software World.</Typography>
            <Typography sx={{color:"white", fontSize:"16.5px", marginTop:"40px"}} align="center">Software created to optimize all aspects of your woodworking production. <br/>
            Our software will streamline your workflow and transform your business.</Typography>
            <Box>
                <Button onClick={(e) => {navigate("/login")}} sx={{width:"165px", color:"black", background:"#00A551",
                marginTop:"25px", textTransform:"none", fontSize:"18px", '&:hover':{backgroundColor:"#00C963", color:"black"}}} size="large" endIcon={<ArrowUpward/>}>Level Up</Button>
                <Button onClick={(e) => {navigate("/products")}} sx={{width:"165px", color:"black", background:"#FF6600",
                margin:"25px 0px 0px 25px", textTransform:"none", fontSize:"18px", '&:hover':{backgroundColor:"#FF7920", color:"black"}}} size="large">View Our Lineup</Button>
            </Box>
            <Typography sx={{color:"white", fontSize:"30px", marginTop:"10%"}} align="center">Now introducing <span style={{color:"#00A551"}}>MAPS</span> Reporting</Typography>
            <img src={MapsReporting} alt="MapsReportingImage" style={{margin:"45px 0px 15px 0px", width:"70%"}}/>
            <Box sx={{display:"flex", marginTop:"20px", alignItems:"center", justifyContent:"center"}}>
                <Typography sx={{color:"white", fontSize:"20px"}} align="center">Powered by</Typography>
                <img src={MAPnbg} alt="MAPSLogo" style={{width:"40px", height:"40px", margin:"20px 0px 20px 15px"}}/>
            </Box>
        </Box>
        </Box>
    );
}