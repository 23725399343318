import { Box, Typography } from "@mui/material";
import React from 'react';
import "../css/Navbar.css";

const Footer = () => {
    const loggedIn = Boolean((localStorage.getItem('weinigPortalUser') != null));

    return(
        <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", height:"55px", minHeight:"55px", background:"white", width:"100%"}}>
            <Box>
                <a href={"/reset-password-request"} style={{textDecoration:"none"}}>
                    <Typography sx={{fontSize:"13px", color:"black", fontWeight:"bold", '&:hover':{textDecoration:"underline"}}}>Reset Password</Typography>
                </a>
            </Box>
            <Box>
                <a href={"https://weinigprivacystatement.s3.us-east-2.amazonaws.com/WeinigPrivacyStatement.pdf"} target="_blank" style={{textDecoration:"none"}}>
                    <Typography sx={{fontSize:"13px", color:"black", fontWeight:"bold", '&:hover':{textDecoration:"underline"}}}>Privacy Statement</Typography>
                </a>
            </Box>
            <Box>
                <Typography sx={{fontSize:"13px", color:"black", fontWeight:"bold"}}>Copyright © {new Date().getFullYear()} Weinig Holz-Her USA Inc. All rights reserved.</Typography>
            </Box>
        </Box>
    );

}

export default Footer;