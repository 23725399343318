import { Box, List, ListItem, ListItemButton, ListItemText, ListItemIcon} from "@mui/material";
import React from 'react';
import { useNavigate } from "react-router-dom";
import "../css/Navbar.css";
import logo from "../imgs/Logos/General Logos/icon.png";
import jwtDecode from "jwt-decode";
import { AdminPanelSettings, Apartment, Article, Home, Info, LocalGroceryStore, ModeNight, Monitor, People } from "@mui/icons-material";

const NavBarSide = () => {
    const navigate = useNavigate();
    var user = JSON.parse(localStorage.getItem("weinigPortalUser"));

    function sendToWebpage(){
        window.open(
            'http://10.72.20.2/?autologin_code=G8atPyVKZPJ07T63A1PPdM595LL8VUeE',
            '_blank'
          );
    }

    let homeBG = "#202020"
    let storetBG = "#202020"
    let softwareBG = "#202020"
    let manualsBG = "#202020"
    let compnayProfileBG = "#202020"
    let userProfileBG = "#202020"
    let adminAccountSearcheBG = "#202020"
    let supportBG = "#202020"

    let highlightedItem = "#00A551"
    let pathName = window.location.pathname

    if(pathName === "/home"){
        homeBG = highlightedItem
    }
    else if(pathName === "/store"){
        storetBG = highlightedItem
    }
    else if(pathName === "/software"){
        softwareBG = highlightedItem
    }
    else if(pathName === "/manuals"){
        manualsBG = highlightedItem
    }
    else if(pathName === "/company-profile"){
        compnayProfileBG = highlightedItem
    }
    else if(pathName === "/user-profile"){
        userProfileBG = highlightedItem
    }
    else if(pathName === "/admin-account-search"){
        adminAccountSearcheBG = highlightedItem
    }
    else if(pathName === "/support"){
        supportBG = highlightedItem
    }

    return(
        <Box display={"flex"} alignItems="center" justifyContent="space-between" flexDirection={"column"} width="9%" minWidth="170px" backgroundColor="#202020" height="100%">
            <Box sx={{marginBottom:"10px", '&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                <img src={logo} width="130px" alt="HolzHerWeinigLogo"/>
            </Box>
            <List sx={{display:"flex", flexDirection:"column", height:"100%", width:"90%"}}>
                <ListItem onClick={() => {navigate("/home")}} sx={{padding:0, height:"50px", borderRadius:"10px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{ padding:0, height:"40px", borderRadius:"10px", background:homeBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <Home style={{color:"#ffffff", width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Home" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => {navigate("/store")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:storetBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <LocalGroceryStore style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Store" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => {navigate("/software")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:softwareBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <Monitor style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Software" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                {/* <ListItem onClick={() => {sendToWebpage()}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:manualsBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <Article style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Manuals" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem> */}
                <ListItem onClick={() => {navigate("/company-profile")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:compnayProfileBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <Apartment style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Company" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => {navigate("/user-profile")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:userProfileBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <People style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Users" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                {parseInt(jwtDecode(user.token).role) === 6 && 
                <ListItem onClick={() => {navigate("/admin-account-search")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:adminAccountSearcheBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <AdminPanelSettings style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Admin" sx={{color:"#ffffff", margin:"3px 10px 3px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                }
                <Box sx={{display:"flex", width:"100%"}}>
                    <Box sx={{borderBottom:"2px solid #CFCFCF", width:"100%"}}/>
                </Box>
                <ListItem onClick={() => {navigate("/support")}} sx={{padding:0, height:"50px", width:"100%"}}>
                    <ListItemButton component="a" href="" sx={{padding:0, height:"40px", borderRadius:"10px", background:supportBG,'&:hover':{backgroundColor:"#393939"}}}>
                        <ListItemIcon sx={{minWidth:15, marginLeft:"22px"}}>
                            <Info style={{color:"#ffffff", width:17, height:17}}/>
                        </ListItemIcon>
                        <ListItemText primary="Support" sx={{color:"#ffffff", margin:"3px 10px 0px 15px", display:"flex", alignItems:"center"}} primaryTypographyProps={{fontSize:16}}/>
                    </ListItemButton>
                </ListItem>
                {/* <ListItem sx={{padding:0, height:"50px"}}>
                    <ListItemButton sx={{padding:0, height:"40px", borderRadius:"10px", display:"flex", alignItems:"center"}}>
                        <ListItemIcon sx={{minWidth:35, marginLeft:"22px"}}>
                            <ModeNight style={{color:"#ffffff",  width:17,  height:17}}/>
                        </ListItemIcon>
                        <Switch size="small" sx={{margin:"0px 10px 0px 5px"}}/>
                    </ListItemButton>
                </ListItem> */}
            </List>
        </Box>
    )
}

export default NavBarSide;