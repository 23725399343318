import { AppBar, Avatar, Button, styled, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import axios from "axios";
import { config } from "../../Functions/Constants";
import { AddStoreNotification } from "../../Functions/StoreNotification";
import SupportMainImage from "../../imgs/Pages/Support/backgroundImage.png";
import avatar from "../../imgs/Logos/General Logos/avatar.png"
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const ProfileIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  alignItems: "center"
}));

const Support = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("weinigPortalUser")));
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const loggedIn = Boolean((localStorage.getItem('weinigPortalUser') != null));
    const navigate = useNavigate();
    const [emailToSend, setEmailToSend] = useState({
        Address: "",
        Subject: "",
        Message: "",
        Type:"",
        AddressFrom: ""
    });

    const click = (e) => {
      setAnchorEl(e.currentTarget);
    }
    const close = () => {
        setAnchorEl(null);
    }
    const Logout = () =>{
        localStorage.removeItem("weinigPortalUser")
        navigate("/login")
    }

    async function submitProblem(){
        emailToSend.Address = "appsgroup@weinig.com";
        emailToSend.AddressFrom = email;
        emailToSend.Subject = subject;
        emailToSend.Message = "Email from " + name + "<br/>" + "Email: " + email  + "<br/>" + "Phone: " + phone + "<br/> <br/>" + description;
        emailToSend.Type = "SupportEmail";
        try {
            const emailAsync = () =>
              axios({
                method: "Post",
                url: config.url.API + "/Email/email-notification",
                data: JSON.stringify(emailToSend),
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "https://weinigportal.com/",
                  Authorization: "Bearer " + user.token,
                },
              });
      
            var response = await emailAsync();
      
            if (response.data.status === 403) {
              setEmailToSend({Address: "", AddressFrom: "", Subject: "", Message: "", Type: ""});
              AddStoreNotification(
                "Error sending your support ticket",
                "danger",
                "You do not have access with your current account to send an email."
              );
            } else if (response.data.statusCode === 401) {
              setEmailToSend({Address: "", AddressFrom: "", Subject: "", Message: "", Type: ""});
              AddStoreNotification(
                "Error sending your support ticket",
                "danger",
                "Could not send you support ticket."
              );
            } else if (response.data.statusCode === 200) {
              setEmailToSend({Address: "", AddressFrom: "", Subject: "", Message: "", Type: ""});
                AddStoreNotification(
                    "Support Ticket Sent Successfuly",
                    "success",
                    "Your support ticket was sent successfuly, we will reach out to you regarding your ticket"
                  );
            } else {
              setEmailToSend({Address: "", AddressFrom: "", Subject: "", Message: "", Type: ""});
              AddStoreNotification(
                "Error sending your support ticket",
                "danger",
                "The server encountered an unexpected error, please try again."
              );
            }
          } catch (error) {
            setEmailToSend({Address: "", AddressFrom: "", Subject: "", Message: "", Type: ""});
            AddStoreNotification(
              "Error sending your support ticket",
              "danger",
              "Could not send you support ticket. Please try again."
            );
          }
    }
    return(
        <Box sx={{width:"100%", height:"100%", backgroundColor:"black", display:"flex", flexDirection:"column", justifyContent:"space-between", overflow:"auto"}}>
            <AppBar sx={{width:"100%", position:"absolute", background:"#00000000"}} elevation={0}>
                {loggedIn && (
                        <ProfileIcon sx={{marginLeft:"auto", marginRight:"20px", marginTop:"2px", marginBottom:"2px"}}>
                            <IconButton onClick={click} aria-controls={open ? 'menu': undefined}
                            aria-haspopup="true" aria-expanded={open ? 'true': undefined}>
                                <Avatar src={avatar} sx={{width:"32px", height:"32px"}}/>
                            </IconButton>
                        </ProfileIcon>
                )}
                {loggedIn && (
                    <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={close}
                    onClick={close}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuItem onClick={Logout}>Logout</MenuItem>
                    </Menu>
                )}
            </AppBar>
            <Box sx={{width:"100%", height:"calc(72% - 55px)", minHeight:"570px", background:"#000000", display:"flex", justifyContent:"space-between"}}>
                <Box sx={{marginLeft:"12%", width:"45%", height:"100%"}}>
                    <Typography sx={{color:"white", fontSize:"40px" , marginTop:"18%"}}>Software Support</Typography>
                    <Box sx={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
                        <Box sx={{width:"100%"}}>
                          <TextField label="name" variant="standard" 
                          value={name} onChange={(e) => {setName(e.target.value)}} 
                          size="large" sx={{borderBottom: "1px solid white", maxWidth: 200, marginBottom:"15px"}}
                          InputProps={{style:{fontSize:"16px", color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:"16px", color:"white"}}} />
                          <TextField label="email" variant="standard" 
                          value={email} onChange={(e) => {setEmail(e.target.value)}} 
                          size="large" sx={{borderBottom: "1px solid white", maxWidth: 200, marginBottom:"15px", marginLeft:"25px"}}
                          InputProps={{style:{fontSize:"16px", color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:"16px", color:"white"}}} />
                        </Box>
                        <Box sx={{width:"100%"}}>
                          <TextField label="phone" variant="standard" 
                          value={phone} onChange={(e) => {setPhone(e.target.value)}} 
                          size="large" sx={{borderBottom: "1px solid white", maxWidth: 200, marginBottom:"15px"}}
                          InputProps={{style:{fontSize:"16px", color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:"16px", color:"white"}}} />
                          <TextField label="subject" variant="standard" 
                          value={subject} onChange={(e) => {setSubject(e.target.value)}} 
                          size="large" sx={{borderBottom: "1px solid white", maxWidth: 200, marginBottom:"15px", marginLeft:"25px"}}
                          InputProps={{style:{fontSize:"16px", color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:"16px", color:"white"}}} />
                        </Box>
                        <TextField label="describe your problem" variant="standard" multiline
                        value={description} onChange={(e) => {setDescription(e.target.value)}} 
                        size="large" sx={{borderBottom: "1px solid white", maxWidth: 415, minHeight:135, maxHeight:135, overflow:"auto", colorScheme:"dark"}}
                        InputProps={{style:{fontSize:"16px", color:"white"}, disableUnderline:true}} InputLabelProps={{style: {fontSize:"16px", color:"white"}}} />

                        <Button onClick={(e) => {submitProblem()}} variant="filled" size="normal" sx={{color:"white", backgroundColor:"#00A551", fontSize:"16px",
                          borderRadius:"10px", textTransform:"none", width:"80px", marginTop:"26px", marginBottom:"26px", '&:hover':{backgroundColor:"#009046"}}}>
                        submit</Button>
                        <Typography sx={{color:"white", fontSize:"14px"}}>*Once submitted, you will receive an email to confirm that we have received your request.</Typography>
                    </Box>
                </Box>
                <Box sx={{width:"65%"}}>
                    <img alt="Support Main" src={SupportMainImage} style={{width:"100%", height:"100%"}}></img>
                </Box>
            </Box>
            
            <Box sx={{height:"calc(28% - 30px)", background:"black", display:"flex", alignContent:"center", marginLeft:"12%", marginRight:"12%", marginTop:"10px", marginBottom:"20px"}}>
              <Box sx={{marginTop:"0px"}}>
                <Typography sx={{color:"white", fontSize:"17px"}}>email &emsp; &nbsp;  &nbsp; appgroup@weinig.com</Typography>
                <Typography sx={{color:"white", fontSize:"17px", marginTop:"10px"}}>phone &emsp; &nbsp; (854) 564-5126</Typography>
                <Typography sx={{color:"white", fontSize:"17px", marginTop:"10px"}}>address &emsp; 124 Crosslake Park Dr, Mooresville, NC 28117</Typography>
              </Box>
            </Box>
          <Footer />
        </Box>
    )
}
export default Support;