import { AppBar, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import EnVisionLogo from "../../imgs/Logos/EnVision/EnVisionLogoSolid.png";
import MillVisionLogo from "../../imgs/Logos/MillVision/MillVisionLogoSolid.png";
import MVLabelerLogo from "../../imgs/Logos/Labeler/LabelerLogoSolid.png";
import PackLogo from "../../imgs/Logos/Pack/PackLogoSolid.png";
import JobViewLogo from "../../imgs/Logos/JobView/JobViewLogoSolid.png";
import AccumulatorLogo from "../../imgs/Logos/Accumulator/AccumulatorLogoSolid.png";
import CadetLogo from "../../imgs/Logos/Cadet/CadetLogoSolid.png";
import MAPSLogo from "../../imgs/Logos/MAPS/MAPSLogoNewest.png";
import PiLogo from "../../imgs/Logos/PerformanceIndicators/PerformanceIndicatorsLogo.png";
import logo from "../../imgs/Logos/General Logos/icon.png";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../FooterPublic";

const Products = () => {
    const [woodProcessing, setWoodProcessing] = useState(true);
    const [productionTracking, setProductionTracking] = useState(true);
    const [productName, setProductName] = useState("");
    let [filteredProducts, setFilteredProducts] = useState([]);
    const navigate = useNavigate();
    
    let products = [
        { name:"MAP Suite", logo: MAPSLogo, info:"Manufacturing Automation Platform Suite is a program where our newest software will used", display:woodProcessing, link:"/maps" },
        { name:"EnVision", logo: EnVisionLogo, info:"EnVision is about reimagining the definition of automation", display:woodProcessing, link:"/envision" },
        { name:"MillVision", logo: MillVisionLogo, info:"MillVision post procceses your incoming data to readable data to the saw", display:woodProcessing, link:"/millvision" },
        { name:"MVLabeler", logo: MVLabelerLogo, info:"MVLabeler is a lable creation tool that allows you to create lables for parts, sheetgood patterns, and nesting", display:productionTracking, link:"/mvlabeler" },
        { name:"Pack", logo: PackLogo, info:"Pack is an inventory management and purchasing tool", display:productionTracking, link:"/pack" },
        { name:"JobView", logo: JobViewLogo, info:"JobView is a production scheduling and capacity viewing tool", display:productionTracking, link:"/jobview" },
        { name:"Cadet", logo: CadetLogo, info:"Cadet allows you to get track of production, perform rework management, and remotely print labels at beamsaws", display:productionTracking, link:"/cadet" },
        { name:"Accumulator", logo: AccumulatorLogo, info:"Accumulator is a wide plank flooring accumulation calculator", display:woodProcessing, link:"/accumulator" },
        { name:"Performance Indicators", logo: PiLogo, info:"Pi is your command post for at-a-glance production insights.", display:productionTracking, link:"/pi" }  
    ]

    useEffect(() => {
        var filteredProductsToReturn = products.reduce(function(filtered, product){
            if(product.display){
                var newProduct = {name: product.name, logo: product.logo, info: product.info, display: product.display}
                filtered.push(newProduct)
            }
            return filtered;
        }, []);
        setFilteredProducts(filteredProductsToReturn);
    }, [woodProcessing, productionTracking])

    useEffect(() => {
        var filteredProductsToReturn = [];
        if(productName !== ""){
            products.forEach(product => {
                if(product.name.toLowerCase().includes(productName.toLowerCase())){
                    filteredProductsToReturn.push(product)
                }
            });
            setFilteredProducts(filteredProductsToReturn);
        }else{
            setFilteredProducts(products);
        }
    },[productName])

    return(
        <Box width="100%" height="100%" backgroundColor="#161616" display="flex" alignItems="center" flexDirection="column" overflow="auto">
            <AppBar sx={{background:"black", height:"100px", width:"100%", position:"static", display:"flex", alignItems:"center"}} elevation={0} >
                <Box sx={{width:"97%", height:"100%", display:"flex", justifyContent:"space-between"}}>
                    <Box sx={{'&:hover':{cursor:"pointer"}}}>
                        <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} onClick={() => {navigate("/landing")}}/>
                    </Box>
                    <Box sx={{display:"flex", alignItems:"center", height:"100%"}}>
                        <Button onClick={(e) => {navigate("/login")}} sx={{height:"48px", color:"white", background:"transparent", textTransform:"none",
                        border:"1px solid white", '&:hover':{backgroundColor:"white", color:"black"}}}>Get Started</Button>
                    </Box>
                </Box>
            </AppBar>
            <Box width="97%" paddingBottom="20px" flex="1">
                <Paper sx={{marginTop:"20px", overflow:"hidden", background:"#202020", borderRadius:"0px", display:"flex", width:"100%", border:"1px solid #202020"}} elevation={0} square>
                    <Typography margin={"15px 15px 15px 15px"} minWidth={"200px"} fontSize={16} fontWeight={"bold"} color={"white"}><span style={{color:"#00A551"}}>Weinig </span> <span style={{color:"#FF6600"}}>Holz-Her </span>Software</Typography>
                    <Box style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>
                        <TextField label="product name" variant="outlined" 
                                value={productName} onChange={(e) => {setProductName(e.target.value)}} 
                                size="small" sx={{
                                    "& .MuiOutlinedInput-root": {"& > fieldset": { borderColor: "white" }},
                                    "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "white"}},
                                    "& .MuiOutlinedInput-root:hover": { "& > fieldset": { borderColor: "white"}},
                                    width:"90%", minWidth: 150, borderRadius:"4px"}}
                                InputProps={{style:{fontSize:"14.5", color:"white"}}} InputLabelProps={{style: {fontSize:14.5, color:"white"}}} />
                        <IconButton size="large" onClick={() => {setProductName(productName)}}>
                            <Search sx={{color:"white"}}/>
                        </IconButton>
                    </Box>
                </Paper>
                <Box sx={{display:"flex", width:"100%", height:"calc(100% - 75px)"}}>
                    <Box sx={{display:"flex", flexDirection:"column", width:"10%", minWidth:"130px", minHeight:"466px"}} >
                        <Paper sx={{width:"100%", background:"#202020", height:"100%", borderRadius:"0px", borderTop:"1px solid #ADADAD"}} elevation={0} square>
                            <Typography margin={"15px 15px 15px 15px"} fontSize={15} color={"white"}>Filter</Typography>
                            <FormGroup>
                                <FormControlLabel sx={{margin:"0px 0px 10px 4px"}} control={<Checkbox checked={woodProcessing} onChange={(e) => {setWoodProcessing(!woodProcessing)}} size="small" sx={{color:"white"}}/>} label={<Typography sx={{fontSize:13, color:"white"}}>wood processing</Typography>}></FormControlLabel>
                                <FormControlLabel sx={{margin:"0px 0px 0px 4px"}} control={<Checkbox checked={productionTracking} onChange={(e) => {setProductionTracking(!productionTracking)}} size="small" sx={{color:"white"}}/>} label={<Typography sx={{fontSize:13, color:"white"}}>production tracking</Typography>}></FormControlLabel>
                            </FormGroup>
                        </Paper>
                    </Box>
                    <Box sx={{display:"flex", flexWrap:"wrap", marginLeft:"7.5px", height:"fit-content", width:"90%"}}>
                        {filteredProducts.map((product, index) => (
                            <Paper key={"Key-" + index} sx={{margin:"15px 7.5px 0px 7.5px", width:"23.5%", minWidth:"300px", minHeight:"210px", background:"#202020", overflow:"hidden", borderRadius:"4px", border:"1px solid #202020"}} elevation={0} square>
                                <Box style={{display:"flex", alignItems:"center"}} margin={"15px 15px 15px 15px"}>
                                    <img alt="Product Logo" src={product.logo} style={{width:"32px", height:"32px"}}></img>
                                    <Typography sx={{minWidth:"120px", fontSize:"15px", fontWeight:"bold", color:"white", marginLeft:"10px"}}>{product.name}</Typography>
                                </Box>
                                <Box style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                                    <Typography sx={{width:"90%", fontSize:"13px", color:"white", minHeight:"75px", marginBottom:"30px"}}>{product.info}</Typography>
                                    <Box sx={{borderBottom:"1px solid #9D9D9D", width:"90%", marginBottom:"10px"}}/>
                                    <Box sx={{width:"90%"}}>
                                        <Button variant="contained" size="small" sx={{float:"right", textTransform:"none", marginBottom:"10px", fontSize:"11.5px", borderRadius:"20px", boxShadow:"none", '&:hover':{boxShadow:"none"}}}
                                        onClick={() => {navigate(product.link)}}>Learn More</Button>
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                </Box>
            </Box>
            <FooterPublic />
        </Box>
    )
}
export default Products;