//imports of react/other components
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

//import of mui (material ui)
import { Box } from "@mui/material";
import { Stack } from "@mui/system";

//imports of our pages/components
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import NavBarTop from "./components/NavBarTop";
import NavBarSide from "./components/NavBarSide";
import Homepage from "./components/pages/Homepage";
import Software from "./components/pages/Software";
import CompanyProfile from "./components/pages/CompanyProfile";
import UserProfile from "./components/pages/UserProfile";
import AdminAccountSearch from "./components/pages/AdminAccountSearch";
import Support from "./components/pages/Support";
import Store from "./components/pages/Store";
import Landing from "./components/Landing";
import ResetRequest from "./components/ResetRequest";
import ResetPassword from "./components/ResetPassword";
import Products from "./components/pages/Products";

//imports of our pages/components/products
import EnVision from "./components/pages/products/Envision";
import MillVision from "./components/pages/products/Millvision";
import MVLabeler from "./components/pages/products/MVLabeler";
import Pack from "./components/pages/products/Pack";
import JobView from "./components/pages/products/JobView";
import Accumulator from "./components/pages/products/Accumulator";
import Cadet from "./components/pages/products/Cadet";
import MAPS from "./components/pages/products/MAPS";
import Footer from "./components/Footer";
import PerformanceIndicators from "./components/pages/products/PerformanceIndicators";

function App() {
 
  return (
    <Box height="100%">
      <ReactNotifications />
      <Router>
        <Routes>
          <Route 
            path="/"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Landing/>
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/login"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Login/>
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/landing"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Landing/>
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/home"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <Homepage />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/store"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                  <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <NavBarTop/>
                    <Store />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/software"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                  <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <NavBarTop/>
                    <Software />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/company-profile"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                  <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <NavBarTop/>
                    <CompanyProfile />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/user-profile"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: "100%"}}>
                  <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <NavBarTop/>
                    <UserProfile/>
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/admin-account-search"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                  <NavBarSide />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <NavBarTop/>
                    <AdminAccountSearch />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/support"
            element={
              <ProtectedRoute>
                <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%', overfill:"auto", colorScheme:"dark"}}>
                  <NavBarSide sx={{height: "100%"}} />
                  <Box sx={{width:"100%", height: "100%"}}>
                    <Support />
                  </Box>
                </Stack>
              </ProtectedRoute>
            }
            />

            <Route 
            path="/envision"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <EnVision />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/millvision"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <MillVision />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/mvlabeler"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <MVLabeler />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/pack"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Pack />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/jobview"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <JobView />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/accumulator"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Accumulator />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/cadet"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <Cadet />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/maps"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <MAPS />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/pi"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <PerformanceIndicators />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/reset-password-request"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Box sx={{width:"100%", height: "100%"}}>
                  <ResetRequest />
                </Box>
              </Stack>
            }
            />

            <Route 
            path="/resetpassword/:token/:username/:email"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <ResetPassword />
              </Stack>
            }
            />

            <Route 
            path="/products"
            element={
              <Stack display="flex" direction="row" justifyContent="space-between" sx={{height: '100%'}}>
                <Products />
              </Stack>
            }
            />

        </Routes>
      </Router>
    </Box>
  );
}

export default App;
