import { AppBar, Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ReactPlayer from 'react-player';
import logo from "../../../imgs/Logos/General Logos/icon.png";
import CadetImg from "../../../imgs/Pages/Products/Cadet/Cadet.png";
import CadetLogo from "../../../imgs/Pages/Products/Cadet/CadetLogoSolid.png";
import CadetImage1 from "../../../imgs/Pages/Products/Cadet/CadetImage1.png";
import CadetImage2 from "../../../imgs/Pages/Products/Cadet/CadetImage2.png";
import CadetImage3 from "../../../imgs/Pages/Products/Cadet/CadetImage3.png";
import { useNavigate } from "react-router-dom";
import FooterPublic from "../../FooterPublic";

const Cadet = () => {
    const [demoVideoModal, setDemoVideoModal] = useState(false);
    const navigate = useNavigate();

    return(
        <Box width="100%" height="100%" backgroundColor="white" display="flex" flexDirection="column" overflow="auto">
            <Box sx={{width:"100%", height:"100%"}}>
                <Box sx={{display:"flex", height:"60%", background:"black"}}>
                    <Box sx={{width:"100%", height:"100%"}}>
                        <AppBar sx={{background:"black", height:"100px", width:"100%", display:"flex", position:"static"}} elevation={0} >
                            <Box sx={{marginLeft:"20px", width:"120px",'&:hover':{cursor:"pointer"}}} onClick={() => {navigate("/home")}}>
                                <img alt="Weinig Holz-Her Logo" src={logo} style={{width:"130px"}} />
                            </Box>
                        </AppBar>
                        <Box sx={{width:"100%", height:"calc(100% - 100px)", background:"black", display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center"}}>
                            <Box sx={{width:"65%", margin:"0% 0% 3% 18%"}}>
                                <img alt="Cadet Logo" src={CadetLogo} style={{width:"70px", height:"70px"}}/>
                                <Typography sx={{fontSize:"35px", fontWeight:"bold", lineHeight:"1.1", marginTop:"10px", color:"white"}}><span style={{color:"#D9D900"}}>Cadet.</span> <br/> Keep your production in order.</Typography>
                                <Typography sx={{fontSize:"15px", fontWeight:"bold", marginTop:"10px", color:"white"}}>Track your production, rework management,
                                 and apply cart slotting capabilities to your process. </Typography>
                                <Box sx={{display:"flex", marginTop:"15px"}}>
                                    {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px",
                                     textTransform:"none", fontSize:"15px", width:"90px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                                    {/* <Button variant="contained" size="small" onClick={(e) => {setDemoVideoModal(true)}} sx={{background:"white",
                                     color:"black", borderRadius:"10px", marginLeft:"20px", textTransform:"none", boxShadow:"none", fontSize:"15px",
                                      width:"90px", fontWeight:"bold", '&:hover':{background:"#FFA800", boxShadow:"none"}}}>demo</Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:"100%"}}>
                        <img alt="Cadet Main" src={CadetImg} style={{width:"65%", height:"100%", marginLeft:"35%"}}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"5%", marginBottom:"5%"}}>
                    <Box sx={{marginLeft:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D9D900"}}>Production Tracking</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Track production through each
                         manufacturing operation.</Typography>
                    </Box>
                    <Box sx={{marginLeft:"6%", marginRight:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D9D900"}}>Rework Management</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Combining the power of EnVision
                         and Cadet rework can now be managed and reprocessed quickly and efficeintly. Allowing 100% complete customer orders.</Typography>
                    </Box>
                    <Box sx={{marginRight:"6%", width:"25.33%", height:"70%"}}>
                        <Typography align="center" sx={{fontSize:"32px", fontWeight:"bold", lineHeight:"1.1", color:"#D9D900"}}>Cart Slotting</Typography>
                        <Typography align="center" sx={{fontSize:"14px", fontWeight:"bold", marginTop:"10px", color:"black"}}>Manage the chaos of optimization
                         and high yeilds, by organizing parts into a cart slot system.</Typography>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", background:"black", paddingBottom:"3%"}}>
                    <Typography sx={{fontSize:"37px", fontWeight:"bold", color:"white", margin:"3% 0% 2% 0%"}}>Discover the <span style={{color:"#D9D900"}}>Benefits</span></Typography>
                    <Box sx={{display:"flex", width:"60%"}}>
                        <img alt="Cadet Cart Slotting" src={CadetImage1} style={{width:"60%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#D9D900", fontWeight:"bold"}}>Cart Slotting</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>With Cadet you can organize your reworked
                             items in carts and therefore keep track of your different items.</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <Box sx={{marginRight:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#D9D900", fontWeight:"bold"}}>Rework</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>Add and track parts that need to be reprocessed within Cadet.</Typography>
                            {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                             fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                        <img alt="Cadet Rework" src={CadetImage2} style={{width:"70%", borderRadius:"3px"}}/>
                    </Box>
                    <Box sx={{display:"flex", width:"60%", marginTop:"3%"}}>
                        <img alt="Cadet Flexible" src={CadetImage3} style={{width:"70%", borderRadius:"3px"}}/>
                        <Box sx={{marginLeft:"3%"}}>
                            <Typography sx={{fontSize:"25px", color:"#D9D900", fontWeight:"bold"}}>Flexible</Typography>
                            <Typography sx={{fontSize:"19px", color:"white", marginTop:"15px"}}>With Cadet you can track your reworked items through every stage of production.</Typography>
                             {/* <Button variant="outlined" size="small" sx={{color:"white", border:"2px solid white", borderRadius:"10px", textTransform:"none",
                              fontSize:"15px", width:"90px", marginTop:"40px", fontWeight:"bold", '&:hover':{border:"2px solid #D9D900"}}}>Buy now</Button> */}
                        </Box>
                    </Box>
                </Box>
                <FooterPublic />
            </Box>
            <Modal open={demoVideoModal} onClose={(e) => {setDemoVideoModal(false)}} 
            sx={{backdropFilter: "blur(1px)", background:"rgba(0,0,0,0.6)", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Box sx={{position:"absolute", width:"75%", height:"80%"}}>
                <ReactPlayer url='https://www.youtube.com/watch?v=TTtBBR1UWR4' controls={true} width='100%' height='100%' />
              </Box>
            </Modal>
        </Box>
    )
}
export default Cadet;